// Loading.js
import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loading = ({ loading }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 9999,
    }}>
      <ClipLoader size={50} color={"#123abc"} loading={loading} />
    </div>
  );
};

export default Loading;
