

import React, { useEffect ,useRef,useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';
import { Link } from 'react-router-dom';
import { API_BASE_URL } from './Config';
const  Sidemenu = () => {
  const [firstname, setFrstname] = useState('');
  const [mobile, setMobile] = useState('');
  const [image, setImage] = useState('');
  const [intertype, setIntertype] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [active, setActive] = useState('');
  const [pincode, setPincode] = useState(localStorage.getItem('pincode'));
  const [featuresone, setFeaturesone] = useState([]);
  useEffect(() => {
            const firstname=localStorage.getItem('firstname');
            const photo=localStorage.getItem('photo');
            const intertype=localStorage.getItem('intertype');
            const state=localStorage.getItem('state');
            const city= localStorage.getItem('city');
            const status=localStorage.getItem('status');
            const mobile=localStorage.getItem('mobile');
            const regid=localStorage.getItem('regid');
            const pincodedata=localStorage.getItem('pincode');
            setFrstname(firstname);
            setMobile(mobile);
            setImage(photo);
            setIntertype(intertype);
            setState(state);
            setCity(city);
            setActive(status);
            setPincode(pincodedata);
            fetch(`${API_BASE_URL}/supervisor/getSupervisorDetail?interntype=${intertype}&state=${state}&city=${city}&pincode=${pincode}`)
    .then(response => response.json())
    .then(data => {
      
      setFeaturesone(data);
    })
    .catch(error => {
     const data= [
    { countable: 0, text: 'State Incharge', path: '' },
    { countable:0, text: 'District Inchangre', path: '' },
    { countable: 0, text: 'Supervisor', path: '' }
  ];
      setFeaturesone(data);
      console.error('Error fetching notifications:', error);
    });
  }, []);

  const imgSrc = `https://apply.aapkiyojana.org/supers/assets/uploads/${image}`;
  return (

    <div
      className="offcanvas offcanvas-start"
      id="affanOffcanvas"
      data-bs-scroll="true"
      tabIndex="-1"
      aria-labelledby="affanOffcanvsLabel"
    >
      <button
        className="btn-close btn-close-white text-reset"
        type="button"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
      <div className="offcanvas-body p-0">
        <div className="sidenav-wrapper">
          {/* Sidenav Profile */}
          <div className="sidenav-profile bg-gradient">
            <div className="sidenav-style1"></div>
            {/* User Thumbnail */}
            <div className="user-profile">
              <img src={imgSrc} alt="" />
            </div>
            {/* User Info */}
            <div className="user-info">
              <h6 className="user-name mb-0">{firstname}</h6>
              <span>{intertype}</span><br />
              <span>{state}<br /> {city}</span>
            </div>
          </div>
          {/* Sidenav Nav */}
          <ul className="sidenav-nav ps-0">
            <li>
              <Link to="/Changeprofile">
                <i className="bi bi-person"></i>Profile
              </Link>
            </li>
            {featuresone.map((feature, index) => (
              <li>
              <Link to={`/${feature.path}`}>
                <i className="bi bi-card-checklist"></i> {feature.text}
                <span className="badge bg-danger rounded-pill ms-2">{feature.countable}+</span>
              </Link>
              </li>
            ))}
           
         
            <li>
              {/* <Link to="/Changeprofile">
                <i className="bi bi-arrow-repeat"></i> Change Profile
              </Link> */}
              {/* <ul>
                <li>
                  <Link to="/Language">App Setting</Link>
                </li>
                <li>
                  <Link to="/shop-list"> Forget me</Link>
                </li>
                <li>
                  <Link to="/shop-details"> Logout</Link>
                </li>
                <li>
                  <Link to="/cart"> Cart</Link>
                </li>
                <li>
                  <Link to="/checkout"> Checkout</Link>
                </li>
              </ul> */}
            </li>
            <li>
              <Link to="/Language">
                <i className="bi bi-gear"></i> App Setting
              </Link>
            </li>
            {/* <li>
              <div className="night-mode-nav">
                <i className="bi bi-moon"></i> Night Mode
                <div className="form-check form-switch">
                  <input
                    className="form-check-input form-check-success"
                    id="darkSwitch"
                    type="checkbox"
                  />
                </div>
              </div>
            </li> */}
            <li>
              <Link to="/Logout">
                <i className="bi bi-box-arrow-right"></i> Logout
              </Link>
            </li>
          </ul>
          {/* Social Info */}
          {/* <div className="social-info-wrap">
            <a href="#">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="#">
              <i className="bi bi-linkedin"></i>
            </a>
          </div> */}
          {/* Copyright Info */}
          <div className="copyright-info">
            <p>
              <span id="copyrightYear"></span> &copy; Made by{' '}
              <a href="#">App ki Yojana</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidemenu;
