import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import HomeFooter from "./HomeFooter";
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import LoadingN from './Loader';
import axios from 'axios';

const Address = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [localities, setLocalities] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState('');
  const [error, setError] = useState(null);
  const [backurl,setBackurl]=useState('');
  const location = useLocation();
  // Function to load data from localStorage on component mount
 
 
  const checkEligibility = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/supervisor/getbenificialShowDetail?regid=${id}`);
      const data = await response.json();
        setPincode(data.pincode || '');
        setCity(data.city || '');
        setState(data.state || '');
        setSelectedLocality(data.block || '');
        getLocation(data.pincode);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    const getid = searchParams.get('getid');
    const state = localStorage.getItem('state');
    const city =localStorage.getItem('city');
    const login = localStorage.getItem('intertype');
    const loginpincode = localStorage.getItem('pincode');
    const seturl=`/userlist?login=${login}&state=${state}&city=${city}&pincode=${loginpincode}`;
    setBackurl(seturl);
    if (getid && getid.trim() !== "") {
      checkEligibility(getid);
    }

  }, []);

  const handlePincodeChange = async (event) => {
    setPincode(event.target.value);
    if (event.target.value.length === 6) {
      await getLocation(event.target.value);
    }
  };
 
  const getLocation = async (pincode) => {
    try {
      setLoading(true);
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: pincode,
          key: 'AIzaSyCYwoaLUyvr9Z5r9Xzw0OkZ6e7EIswM3NY'
        }
      });

      if (response.data.status === 'OK') {
        const result = response.data.results[0];
        const addressComponents = result.address_components;

        const cityComponent = addressComponents.find(component =>
          component.types.includes('locality')
        );

        const stateComponent = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1')
        );

        setCity(cityComponent ? cityComponent.long_name : '');
        setState(stateComponent ? stateComponent.long_name : '');
        setLocalities(result.postcode_localities || []);
        setError(null);
        setLoading(false);
      } else {
        setError('No results found');
        setCity('');
        setState('');
        setLocalities([]);
      }
    } catch (error) {
      setError('Error fetching location data');
      setCity('');
      setState('');
      setLocalities([]);
    }
  };

  const handleLocalityChange = (event) => {
    setSelectedLocality(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const reg_id = searchParams.get('getid');
    const backstate = searchParams.get('state');
    const backcity = searchParams.get('city');
  
    try {
      const response = await axios.post(`${API_BASE_URL}/cpanel/saveProfileDataaddress`, {
        pincode,
        city,
        state,
        locality: selectedLocality,
        reg_id
      });
      const result = response.data;
      if (result.Status) {
        notify('success', 'Profile updated successfully');
        navigate(`/Editprofile?getid=${reg_id}&state=${backstate}&city=${backcity}`);
      } else {
        navigate(`/Editprofile?getid=${reg_id}&state=${backstate}&city=${backcity}`);
       // notify('error', result.message);
      }
    } catch (error) {
      notify('error', 'Error submitting profile data');
    } finally {
      setLoading(false);
    }
  };

  // Save data to localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem('addressData', JSON.stringify({ pincode, city, state, locality: selectedLocality }));
  }, [pincode, city, state, selectedLocality]);

  return (
    <>
      <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to={backurl}>
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Address</h2>
              </div>
              <div className="setting-wrapper">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6>Your Location</h6>
          </div>
        </div>
        {loading && <LoadingN />}
        <div className="container">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="pincode">Pin Code</label>
                  <input
                    className="form-control"
                    id="pincode"
                    type="text"
                    value={pincode}
                    onChange={handlePincodeChange}
                    maxLength="6"
                    readOnly
                  />
                </div>
                {city !== "" &&
                  <>
                    <div className="form-group">
                      <label className="form-label" htmlFor="state">State</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        value={state}
                        readOnly
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="city">City</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        value={city}
                        readOnly
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Block / Village / Localities</label>
                      <select
                        className="form-control"
                        value={selectedLocality}
                        onChange={handleLocalityChange}
                        required
                      >
                        <option value="">
                          Block / Village / Localities
                        </option>
                        {localities.map((locality, index) => (
                          <option key={index} value={locality}>
                            {locality}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                }
                <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                  Update Profile <i className="bi bi-arrow-right fz-16 ms-1"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Address;
