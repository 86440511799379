
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import './App.css';

import React, { useEffect, useState, useContext,useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from './Config';
import './cangrat.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HomeFooter from "./HomeFooter";
import logo from './images/3.png';  // Path to your logo image
import profilePic from './images/user1.png';  // Path to your profile picture
import qrCode from './images/qr.png';  // Path to your QR code image
import background from './images/55.png';  // Path to your background image
const CardView = () => {
 
  const navigate = useNavigate();
  const location = useLocation();
  const [checkprofile, setCheckprofile] = useState(0); 
  const [cardnumber, setCardnumber] = useState(''); 
  
  const [firstname, setFirstname] = useState(0); 
  const [lastname, setLastname] = useState(0); 
  const [gender, setGender] = useState(0); 
  const [dob, setDob] = useState(0); 
  const [photo,setPhoto]=useState();
  const [barcode,setBarcode]=useState('');
  const [englishText, setEnglishText] = useState('');
  const [hindiText, setHindiText] = useState('');
  const handleEdit = async(id) => {
    const switchProfileUrl = `${API_BASE_URL}/cpanel/checkcompeletprofile?userid=${id}`;
  try {
      const response = await fetch(switchProfileUrl, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
       const responseData = await response.json();
       setCheckprofile(responseData.completed);
       setCardnumber(responseData.cardnumber);
       setPhoto(responseData.photo);
       setBarcode(responseData.qrcode);
       setFirstname(responseData.firstname);
       setLastname(responseData.lastname);
       setGender(responseData.selectGender);
       setDob(responseData.selectedDate);
       handleKeyPress(responseData.firstname+' '+responseData.lastname);
 } catch (error) {
      console.error(`Error switching profile: ${error}`);
    } 
 
  };

  useEffect(() => {
    const regid=localStorage.getItem('cardId');
      handleEdit(regid);
   
  }, []);
  const cardRef = useRef(null);

  const handleDownloadPdf = async () => {
    const canvas = await html2canvas(cardRef.current, {
      allowTaint: true,
      useCORS: true,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addImage(imgData, 'PNG', 0, 0, 410, 197);
    pdf.save('card.pdf');
  };
  const handleKeyPress = async (englishText) => {
   
    const response = await fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURI(englishText)}`);
    const result = await response.json();
    if (result[0] !== undefined && result[0] !== null) {
      setHindiText(result[0][0][0]);
    } else {
      setHindiText('------');
    }
  
};
  return (
    <>
       <div className="header-area" id="headerArea" style={{position:'unset'}}>
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Home">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Congratulations</h2>
              </div>
              <div className="setting-wrapper">
                <div className="setting-trigger-btn" id="settingTriggerBtn">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                  </Link>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="app" style={{width:'100%',maxWidth: '477px'}}>
      <div className="mt-5">
      <div className="check-icon mt-5" style={{marginTop:'100px'}}>
          
          <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="70px" viewBox="0 0 20 20">
    <path fill="#025ce2" d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,1.39534884 C5.24778239,1.39534884 1.39534884,5.24778239 1.39534884,10 C1.39534884,14.7522176 5.24778239,18.6046512 10,18.6046512 C14.7522176,18.6046512 18.6046512,14.7522176 18.6046512,10 C18.6046512,5.24778239 14.7522176,1.39534884 10,1.39534884 Z M14.9217059,6.58259135 C15.2023597,6.83364758 15.2263532,7.26468369 14.975297,7.54533745 L10.0661666,13.0332153 C9.79907323,13.3317969 9.33334374,13.336857 9.05982608,13.0441491 L5.71907855,9.46901192 C5.46198377,9.19387916 5.47660645,8.76242348 5.75173921,8.5053287 C6.02687198,8.24823392 6.45832765,8.26285659 6.71542243,8.53798936 L9.54702033,11.5682545 L13.9589598,6.63618243 C14.210016,6.35552866 14.6410521,6.33153512 14.9217059,6.58259135 Z"/>
  </svg>

  <h1>Congratulations!</h1>
        <p>You have completed your profile</p> 
      <p className="eligibility">Your card has been processed.</p>
        <p className="eligibility">You may proceed with further actions.</p> 
    
        <div className="container">
            <div className="pt-3"></div>
            <div 
     
     style={{
       backgroundImage: `url(${background})`,
     
     
       backgroundSize: 'contain',
       display: 'flex',
       justifyContent: 'space-between',
       width:'100%',
       maxWidth:'365px',
       backgroundRepeat: 'no-repeat',
       border: '2px solid red'
     
     }}
   >
       <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
     <div className="logo" style={{ marginTop: '20px' }}></div>
     <div className="scheme-name"></div>
       </div>
       <div class="container">
   <div class="row">
     <div class="col"  style={{marginTop:'42px',width:'100%',maxWidth:'110px',alignItems:'center'}}>
     {photo ? (
  <img
    src={photo}
    style={{
      width: '83px',
      marginLeft: '-6px',
      borderRadius: '44px',
      marginTop: '7px',
      marginLeft: '8px'
    }}
    alt="User Photo"
  />
) : (
  <img
    src={profilePic}
    style={{
      width: '83px',
      marginLeft: '-6px',
      borderRadius: '44px',
      marginTop: '7px',
      marginLeft: '8px'
    }}
    alt="User Photo"
  />
)}
          <br/>
             <strong style={{fontSize:'1.5vh',marginTop:'10px',float:'right'}}>{cardnumber}</strong>
     </div>
   
     <div class="col" style={{marginTop:'56px'}}>
           <strong style={{fontSize:'1.5vh',float:'left'}}>{hindiText} / {firstname} {lastname}</strong><br/>
           <strong style={{fontSize:'1.5vh',float:'left'}}>जन्म तिथि /{dob}</strong><br/>
           <strong style={{fontSize:'1.5vh',float:'left'}}>लिंग /  {gender}</strong>
           <img
             src={barcode}
             alt={firstname}
             style={{ width: '74px',float:'right' ,marginBottom:'40px',padding:'6px' }}
         
           />
</div>
{/* <div class="col "  style={{marginTop:'140px',marginBottom:'40px'}}>

</div> */}
</div>
</div>

</div>
    <div className="pt-3"></div>
       </div>
          </div>
    
  
     
      </div>
     
    </div>
    
    <HomeFooter />
    </>
  );
};

export default CardView;
