import React, { useEffect ,useState} from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from "./HomeFooter";
import { BrowserRouter, Routes, Route ,Router} from "react-router-dom";
const Language = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('English');

    // Function to handle language selection
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.id);
      };
   
  return (
   <>
  
  <Settingpop/>
  <div>
      <div className="header-area" id="headerArea">
        <div className="container">
          <div className="header-content position-relative d-flex align-items-center justify-content-between">
            <div className="back-button">
       <Link to="/Home">
        <i className="bi bi-arrow-left-short"></i>
      </Link>
            </div>
            <div className="page-heading">
              <h6 className="mb-0">Select Language</h6>
            </div>
            <div className="setting-wrapper">
              <div className="setting-trigger-btn" id="settingTriggerBtn">
                <i className="bi bi-gear"></i>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-wrapper py-3">
      <div className="container">
        {/* Language Area */}
        <div className="card">
          <div className="card-body">
            <div className="language-area-wrapper">
              <ul className="ps-0 language-lists">
                {/* English */}
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="languageRadio"
                      id="English"
                      checked={selectedLanguage === 'English'}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="English">
                      English
                    </label>
                  </div>
                </li>
                {/* Afrikaans */}
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="languageRadio"
                      id="Hindi"
                      checked={selectedLanguage === 'Hindi'}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="Hindi">
                      Hindi
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="languageRadio"
                      id="Afrikaans"
                      checked={selectedLanguage === 'Afrikaans'}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="Afrikaans">
                      Afrikaans
                    </label>
                  </div>
                </li>
                {/* Add other languages similarly */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    </div>
   <HomeFooter/>

   
 
   </>
  );
};

export default Language;
