import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import HomeFooter from "./HomeFooter";
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import LoadingN from './Loader';

const Editprofile = () => {
  const [loading, setLoading] = useState(false);
  const [editId, setEditid] = useState(false);
  const [backurl,setBackurl]=useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [profileData, setProfileData] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    selectGender: '',
    selectBusiness: '',
    selectedDate: '',
    age: '',
    maritalstatus: '',
    relation:'',
    relationType:'',
    bank_account: '',
    monthly_income: '',
    own_land: '',
    ration_card_type: '',
    religion: '',
    caste: '',
    nature_of_Job: '',
    occupation: '',
  });

  const [selectOptions, setSelectOptions] = useState({
    relation:[],
    relationType:[],
    maritalstatus: [],
    bank_account: [],
    monthly_income: [],
    own_land: [],
    ration_card_type: [],
    religion: [],
    caste: [],
    nature_of_Job: [],
    occupation: [],
  });

  const checkEligibility = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/supervisor/getbenificialShowDetail?regid=${id}`);
      const data = await response.json();
      
      // Extract the required fields from the data object
      const {
        firstname, lastname, mobile, selectGender, selectBusiness, selectedDate, age, state,
        maritalstatus, relation, relationType, bank_account, monthly_income, own_land, 
        ration_card_type, religion, caste, nature_of_Job, occupation
      } = data;
  
      // Create a new object with the required fields
      const profileData = {
        firstname, lastname, mobile, selectGender, selectBusiness, selectedDate, age, state,
        maritalstatus, relation, relationType, bank_account, monthly_income, own_land, 
        ration_card_type, religion, caste, nature_of_Job, occupation
      };
  
      // Set the new object to the state
      setProfileData(profileData);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  

  const handleAlertClick = (reg_id) => {
    checkEligibility(reg_id);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const getid = searchParams.get('getid');
    const backstate = searchParams.get('state');
    const backcity = searchParams.get('city');
    const seturl=`/Address?getid=${getid}&state=${backstate}&city=${backcity}`;
    setBackurl(seturl);
    if (getid && getid.trim() !== "") {
      handleAlertClick(getid);
      setEditid(getid);
    }

    const titles = [
      'firstname', 'lastname', 'mobile', 'selectGender', 'selectBusiness', 'selectedDate', 'age','state',
      'maritalstatus','relation','relationType','bank_account', 'monthly_income', 'own_land', 'ration_card_type',
      'religion', 'caste', 'nature_of_Job', 'occupation',
    ];

    titles.forEach((title) => {
      const storedValue = localStorage.getItem(title);
      if (storedValue) {
        setProfileData((prevData) => ({ ...prevData, [title]: storedValue }));
      }
    });

    titles.forEach(async (title) => {
      if (!['firstname', 'lastname', 'mobile', 'selectGender', 'selectBusiness', 'selectedDate', 'age'].includes(title)) {
        try {
          const response = await fetch(`${API_BASE_URL}cpanel/getAllMaster/${title}`);
          const data = await response.json();
          setSelectOptions((prevOptions) => ({ ...prevOptions, [title]: data }));
        } catch (error) {
          console.error(`Error fetching ${title} data:`, error);
        }
      }
    });
  }, [location.search]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [id]: value }));
    localStorage.setItem(id, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reg_id = editId;
    const dataToSubmit = { ...profileData, reg_id };
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}cpanel/saveProfileData`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSubmit)
      });
      const result = await response.json();
      if (result.Status) {
        navigate('/Congratulation');
      } else {
        notify('error', result.message);
        navigate('/Congratulation');
      }
    } catch (error) {
      console.error('Error submitting profile data:', error);
    } finally {
      setLoading(false);
    }
  };

  const labelMapping = {
    relation:'Relation',
    relationType:'Relation Type',
    maritalstatus: 'Marital status',
    bank_account: 'Do You Have a Bank Account?',
    monthly_income: 'Monthly income',
    own_land: 'Do you or any of your family members own any land?',
    ration_card_type: 'Ration card type',
    religion: 'What is your religion?',
    caste: 'Caste Category',
    nature_of_Job: 'Nature of Job',
    occupation: 'What is your present occupational status?',
  };

  return (
    <>
      <NotificationExample />
      <div className="header-area" id="headerArea">
        <div className="container">
          <div className="header-content position-relative d-flex align-items-center justify-content-between">
            <div className="back-button">
              <Link to={backurl}>
                <i className="bi bi-arrow-left-short"></i>
              </Link>
            </div>
            <div className="page-heading">
              <h2 className="mb-0">Profile</h2>
            </div>
            <div className="setting-wrapper">
              <Link to="/Language">
                <i className="bi bi-gear"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6>Complete Profile to check eligibility</h6>
          </div>
        </div>
        {loading && <LoadingN />}
        <div className="container">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="firstname">First Name</label>
                  <input
                    className="form-control"
                    id="firstname"
                    type="text"
                    value={profileData.firstname}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="lastname">Last Name</label>
                  <input
                    className="form-control"
                    id="lastname"
                    type="text"
                    value={profileData.lastname}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="mobile">Mobile</label>
                  <input
                    className="form-control"
                    id="mobile"
                    type="text"
                    value={profileData.mobile}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectGender">Gender</label>
                  <input
                    className="form-control"
                    id="selectGender"
                    type="text"
                    value={profileData.selectGender}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectBusiness">Are you business man?</label>
                  <input
                    className="form-control"
                    id="selectBusiness"
                    type="text"
                    value={profileData.selectBusiness}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectedDate">Date</label>
                  <input
                    className="form-control"
                    id="selectedDate"
                    type="date"
                    value={profileData.selectedDate}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="age">Age</label>
                  <input
                    className="form-control"
                    id="age"
                    type="number"
                    value={profileData.age}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                {Object.keys(profileData)
                  .filter(key => !['firstname', 'lastname', 'mobile', 'selectGender', 'selectBusiness', 'selectedDate', 'age'].includes(key))
                  .map((title) => (
                    <div className="form-group" key={title}>
                      <label className="form-label" htmlFor={title}>{labelMapping[title] || title}</label>
                      <select
                        className="form-control"
                        id={title}
                        value={profileData[title]}
                        onChange={handleChange}
                      >
                        <option value="">Select {labelMapping[title] || title}</option>
                        {Array.isArray(selectOptions[title]) && selectOptions[title].map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </select>
                    </div>
                  ))}

                <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                  Complete your profile
                  <i className="bi bi-arrow-right fz-16 ms-1"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Editprofile;
