import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from './HomeFooter';
import { Link, useLocation } from 'react-router-dom';
import { API_BASE_URL } from './Config';
import { Sheet } from 'react-modal-sheet';
import NotificationExample, { notify } from './NotificationExample';

const AccordionItem = ({ id, headerId, targetId, colorClass, title, children, isOpen, toggle }) => (
  <div className={`accordion-item ${colorClass}`}>
    <div className="accordion-header" id={headerId} onClick={() => toggle(id)}>
      <h6 className={`collapsed ${isOpen ? '' : 'collapsed'}`}>
        <i className={`bi ${isOpen ? 'bi-dash-lg' : 'bi-plus-lg'}`}></i> {title}
      </h6>
    </div>
    <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`} id={targetId} aria-labelledby={headerId}>
      <div className="accordion-body">
        {children}
      </div>
    </div>
  </div>
);

const Yojanadetail = () => {
  const location = useLocation();
  const [profiles, setProfiles] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, showQuestion] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showNextQuestion, setShowNextQuestion] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const { yid, description, details, statelocation, benefits, eligibility, exclusions, application_process, documents_required, page } = location.state || {};
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setOpenAccordion((prevOpenAccordion) => (prevOpenAccordion === id ? null : id));
  };

  const checkEligibility = (id) => {
    getQuestion(id);
    showQuestion(true);
  };

  const getQuestion = async (id) => {
    const apiURL = `${API_BASE_URL}/cpanel/getQuestion?sid=${id}`;

    try {
      const response = await fetch(apiURL, { method: 'GET' });
      const responseData = await response.json();
      setQuestions(responseData); // Store the fetched data
      setCorrectAnswers(responseData.map(q => q.answer)); // Store the correct answers
    } catch (error) {
      notify('error', 'Unable to fetch question. Try after some time.');
    } finally {
      setSelectedProfileId(id);
    }
  };

  const handleAnswerChange = async (questionId, selectedValue, relatedans, answer) => {
    // Update selected answers
    const updatedAnswers = { ...selectedAnswers, [questionId]: selectedValue };
    setSelectedAnswers(updatedAnswers);
  
    // Fetch related questions based on the selected answer
    const encodedValue = encodeURIComponent(selectedValue);
    await moreQuestion(questionId, encodedValue);
  
    // Hide the previously selected option
    const optionsToHide = Object.keys(selectedAnswers)
      .filter((key) => key !== questionId && selectedAnswers[key] === selectedValue)
      .map((key) => encodeURIComponent(selectedAnswers[key]));
    optionsToHide.forEach((option) => {
      const questionIndex = questions.findIndex((q) => q.related_ans === option);
      if (questionIndex !== -1) {
        questions.splice(questionIndex, 1);
      }
    });
  
    setShowNextQuestion(true);
  };
  
  const moreQuestion = async (questionId, relatedans) => {
    const apiURL = `${API_BASE_URL}/cpanel/moreQuestion?sid=${questionId}&related=${relatedans}`;
    try {
      const response = await fetch(apiURL, { method: 'GET' });
      const responseData = await response.json();
  
      setQuestions((prevQuestions) => {
        if (!relatedans || relatedans === 'none') {
          return prevQuestions.filter(q => q.eligibility_id !== questionId);
        }
  
        const updatedQuestions = prevQuestions.map((question) => {
          const newQuestion = responseData.find(q => q.eligibility_id === question.eligibility_id);
          if (newQuestion) {
            if (newQuestion.related === relatedans) {
              return newQuestion;
            }
          }
          return question;
        });
  
        responseData.forEach(newQuestion => {
          if (!updatedQuestions.find(q => q.eligibility_id === newQuestion.eligibility_id)) {
            updatedQuestions.push(newQuestion);
          }
        });
  
        return updatedQuestions;
      });
  
      setCorrectAnswers((prevAnswers) => {
        if (!relatedans || relatedans === 'none') {
          return prevAnswers.filter(answer => answer.eligibility_id !== questionId);
        }
  
        const updatedAnswers = prevAnswers.map((answer) => {
          const newQuestion = responseData.find(q => q.eligibility_id === answer.eligibility_id);
          if (newQuestion) {
            if (newQuestion.related === relatedans) {
              return newQuestion.answer;
            }
          }
          return answer;
        });
  
        responseData.forEach(newQuestion => {
          if (!updatedAnswers.find(answer => answer.eligibility_id === newQuestion.eligibility_id)) {
            updatedAnswers.push(newQuestion.answer);
          }
        });
  
        return updatedAnswers;
      });
    } catch (error) {
      notify('error', 'Unable to fetch question. Try after some time.');
    } finally {
      setSelectedProfileId(questionId);
    }
  };
  
  

  // const moreQuestion = async (questionId, relatedans) => {
  //   const apiURL = `${API_BASE_URL}/cpanel/moreQuestion?sid=${questionId}&related=${relatedans}`;
  //   try {
  //     const response = await fetch(apiURL, { method: 'GET' });
  //     const responseData = await response.json();
  
  //     setQuestions((prevQuestions) => {
  //       const updatedQuestions = [...prevQuestions];
  
  //       responseData.forEach(newQuestion => {
  //         const existingIndex = updatedQuestions.findIndex(q => q.eligibility_id === newQuestion.eligibility_id);
  
  //         if (existingIndex !== -1) {
  //           // Remove the existing question if it matches relatedans
  //           if (updatedQuestions[existingIndex].related === relatedans) {
  //             updatedQuestions.splice(existingIndex, 1);
  //           } else {
  //             updatedQuestions[existingIndex] = newQuestion;
  //           }
  //         } else {
  //           updatedQuestions.push(newQuestion);
  //         }
  //       });
  
  //       return updatedQuestions;
  //     });
  
  //     setCorrectAnswers((prevAnswers) => {
  //       const updatedAnswers = [...prevAnswers];
  
  //       responseData.forEach(newQuestion => {
  //         const existingIndex = updatedAnswers.findIndex(answer => answer.eligibility_id === newQuestion.eligibility_id);
  
  //         if (existingIndex !== -1) {
  //           // Remove the existing answer if it matches relatedans
  //           if (updatedAnswers[existingIndex].related === relatedans) {
  //             updatedAnswers.splice(existingIndex, 1);
  //           } else {
  //             updatedAnswers[existingIndex] = newQuestion.answer;
  //           }
  //         } else {
  //           updatedAnswers.push(newQuestion.answer);
  //         }
  //       });
  
  //       return updatedAnswers;
  //     });
  //   } catch (error) {
  //     notify('error', 'Unable to fetch question. Try after some time.');
  //   } finally {
  //     setSelectedProfileId(questionId);
  //   }
  // };
  
  // const moreQuestion = async (questionId, relatedans) => {
  //   const apiURL = `${API_BASE_URL}/cpanel/moreQuestion?sid=${questionId}&related=${relatedans}`;
  //   try {
  //     const response = await fetch(apiURL, { method: 'GET' });
  //     const responseData = await response.json();
  //     const updatedAnswers = { ...selectedAnswers, [questionId]: responseData };
  //     setQuestions(updatedAnswers); // Append new questions to the existing list
  //     setCorrectAnswers((prevAnswers) => [...prevAnswers, ...responseData.map(q => q.answer)]); // Append new correct answers to the existing list
  //   } catch (error) {
  //     notify('error', 'Unable to fetch question. Try after some time.');
  //   } finally {
  //     setSelectedProfileId(questionId);
  //   }
  // };


  const validateAnswers = () => {
    const isAllAnswersCorrect = questions.every((question, index) => 
      selectedAnswers[question.id] === question.answer
    );
    if (isAllAnswersCorrect) {
      notify('success', 'All answers are correct! Eligibility confirmed.');
    } else {
      notify('error', 'Some answers are incorrect. Eligibility failed.');
    }
  };

  return (
    <>
      <Settingpop />
   
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to={`/${page}`}>
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Scheme Details</h2>
              </div>
              <div className="setting-wrapper">
                <div className="setting-trigger-btn" id="settingTriggerBtn">
                  <Link to="/Language">
                    <i className="bi bi-gear"></i>
                  </Link>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="container">
          <div className="pt-3 d-block"></div>
        </div>
        <div className="blog-description py-3">
          <div className="container">
            <h3 className="mb-3" style={{color:'rgb(22 163 74)'}}>
              <i className="bi bi-award-fill"></i> {description}
            </h3>
            <div className="d-flex align-items-center mb-4">
              <span className="ms-2">
                <i className="bi bi-building"></i> {statelocation}
              </span>
            </div>
            <div className="accordion accordion-style-five" id="accordionStyle5">
              <AccordionItem
                id="accordionFive1"
                headerId="accordionFive1"
                targetId="accordionStyleFive1"
                colorClass="accordion-bg-primary"
                title="Details"
                isOpen={openAccordion === 'accordionFive1'}
                toggle={toggleAccordion}
              >
                <div dangerouslySetInnerHTML={{ __html: details }} id="details"></div>
              </AccordionItem>

              <AccordionItem
                id="accordionFive2"
                headerId="accordionFive2"
                targetId="accordionStyleFive2"
                colorClass="accordion-bg-warning"
                title="Benefits"
                isOpen={openAccordion === 'accordionFive2'}
                toggle={toggleAccordion}
              >
                <div dangerouslySetInnerHTML={{ __html: benefits }} id="benefits"></div>
              </AccordionItem>

              <AccordionItem
                id="accordionFive3"
                headerId="accordionFive3"
                targetId="accordionStyleFive3"
                colorClass="accordion-bg-success"
                title="Eligibility"
                isOpen={openAccordion === 'accordionFive3'}
                toggle={toggleAccordion}
              >
                <div dangerouslySetInnerHTML={{ __html: eligibility }} id="eligibility"></div>
              </AccordionItem>

              <AccordionItem
                id="accordionFive4"
                headerId="accordionFive4"
                targetId="accordionStyleFive4"
                colorClass="accordion-bg-info"
                title="Exclusions"
                isOpen={openAccordion === 'accordionFive4'}
                toggle={toggleAccordion}
              >
                <div dangerouslySetInnerHTML={{ __html: exclusions }} id="exclusions"></div>
              </AccordionItem>

              <AccordionItem
                id="accordionFive5"
                headerId="accordionFive5"
                targetId="accordionStyleFive5"
                colorClass="accordion-bg-danger"
                title="Application Process"
                isOpen={openAccordion === 'accordionFive5'}
                toggle={toggleAccordion}
              >
                <div dangerouslySetInnerHTML={{ __html: application_process }} id="application_process"></div>
              </AccordionItem>

              <AccordionItem
                id="accordionFive6"
                headerId="accordionFive6"
                targetId="accordionStyleFive6"
                colorClass="accordion-bg-danger"
                title="Document Required"
                isOpen={openAccordion === 'accordionFive6'}
                toggle={toggleAccordion}
              >
                <div dangerouslySetInnerHTML={{ __html: documents_required }} id="documents_required"></div>
              </AccordionItem>

              <AccordionItem
                id="accordionFive7"
                headerId="accordionFive7"
                targetId="accordionStyleFive7"
                colorClass="accordion-bg-danger"
                title="कैसे आवेदन करें। प्रक्रिया क्या होगी"
                isOpen={openAccordion === 'accordionFive7'}
                toggle={toggleAccordion}
              >
                <div id="documents_required">Coming Soon..</div>
              </AccordionItem>
            </div>
            <div className="fixed-button-container mb-5">
              <button className="btn btn-primary" onClick={() => checkEligibility(yid)}>
                <i className="bi bi-check-circle"></i> Check Eligibility
              </button>
              <button className="btn btn-success">
                <i className="bi bi-box-arrow-up-right"></i> Click to Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <Sheet isOpen={showConfirmation} onClose={() => showQuestion(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
          <NotificationExample />
            <div className="p-4" style={{ height: 'auto', overflow: 'scroll' }}>
              <strong style={{color:'rgb(22 163 74)'}}>
                <i className="bi bi-award-fill"></i> {description}
              </strong>
              {questions.map((question, index) => {
                const options = question.otption1 ? question.otption1.split(',') : [];
                return (
                  <div className="mt-3" style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                    <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
                    <div className="d-flex flex-wrap">
                      {options.map((option, idx) => (
                        <div className="form-check me-3" key={idx}>
                          <input
                            type="radio"
                            id={`option${index}-${idx}`}
                            name={`question${index}`}
                            value={option}
                            onChange={() => handleAnswerChange(question.eligibility_id, option,question.related_ans,question.answer)}
                            className="form-check-input"
                          />
                          <label htmlFor={`option${index}-${idx}`} className="form-check-label">
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-secondary me-2" onClick={() => showQuestion(false)}>
                  Cancel
                </button>
                <button className="btn btn-success" onClick={validateAnswers} disabled={!Object.keys(selectedAnswers).length}>
                Submit
                </button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <HomeFooter />
    </>
  );
};

export default Yojanadetail;
