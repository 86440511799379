import React, { useEffect, useState } from 'react';
import Header from "./Header";
import Sidemenu from "./Sidemenu";
import HomeFooter from "./HomeFooter";
import Testimonialslider from "./Testimonialslider";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'tiny-slider/src/tiny-slider.scss';
import './App.css'
import { API_BASE_URL } from './Config';
import { Container, Card, Nav, Tab,Alert ,Tooltip, Toast} from 'react-bootstrap';
import { useLocation, useNavigate ,Link} from "react-router-dom";
import { Sheet } from 'react-modal-sheet';
import Table from 'react-bootstrap/Table';
import LoadingN from './Loader';
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPreloader, setShowPreloader] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false); // New state for controlling toast visibility
  const [firstname, setFrstname] = useState('');
  const [mobile, setMobile] = useState('');
  const [image, setImage] = useState('');
  const [intertype, setIntertype] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState(localStorage.getItem('pincode'));
  const [active, setActive] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [featuresone, setFeaturesone] = useState([]);
  const [showConfirmation, showQuestion] = useState(false);
  const [showinsheet, setShowinsheet] = useState({});
  const [showscheme, setScheme] = useState({});
  const [clickedAlert, setClickedAlert] = useState(null);
  
  useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const getid = searchParams.get('getid');
    if (getid && getid.trim() !== "") {
      handleAlertClick(getid);
    }
    
    const firstname=localStorage.getItem('firstname');
    const photo=localStorage.getItem('photo');
    const intertype=localStorage.getItem('intertype');
    const state=localStorage.getItem('state');
    const city= localStorage.getItem('city');
    const status=localStorage.getItem('status');
    const mobile=localStorage.getItem('mobile');
    const regid=localStorage.getItem('regid');
    const pincodedata=localStorage.getItem('pincode');
    setFrstname(firstname);
    setMobile(mobile);
    setImage(photo);
    setIntertype(intertype);
    setState(state);
    setCity(city);
    setActive(status);
    setPincode(pincodedata);
    fetch(`${API_BASE_URL}/supervisor/getbenificial?getid=${regid}`)
  .then(response => response.json())
  .then(data => {
    if (data.length === 0) {
      // If no data is available, set default notification
      const defaultNotification = [
        {
          icon: "bi bi-info-circle-fill h1 mb-0",
          title: 'No notifications available',
          message: 'I am waiting for a notification to proceed with further action.',
          unread: true,
          schemeno:'No',
          cardno:'No'
          
        }
      ];
      setNotifications(defaultNotification);
    } else {
      setNotifications(data);
    }
  })
  .catch(error => {
    // In case of fetch error, set default notification
    const defaultNotification = [
      {
        icon: "bi bi-info-circle-fill h1 mb-0",
        title: 'No notifications available',
        message: 'I am waiting for a notification to proceed with further action.',
        unread: true
      }
    ];
    setNotifications(defaultNotification);
    console.error('Error fetching notifications:', error);
  });


    fetch(`${API_BASE_URL}/supervisor/getSupervisorDetail?interntype=${intertype}&state=${state}&city=${city}&pincode=${pincode}`)
    .then(response => response.json())
    .then(data => {
      
      setFeaturesone(data);
      setLoading(false);
    })
    .catch(error => {
     const data= [
    { countable: 0, text: 'State Incharge', path: 'Home' },
    { countable:0, text: 'District Inchangre', path: 'Home' },
    { countable: 0, text: 'Supervisor', path: 'Home' }
  ];  setLoading(false);
      setFeaturesone(data);
      console.error('Error fetching notifications:', error);
    });
    
  }, [location.search]); // Run only once after component mount

  const handleOfflineClick = () => {
    setAlertText('Oops! No internet connection.');
    setAlertColor('#ea4c62');
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };
  

  const handleOnlineClick = () => {
    setAlertText('Your internet connection is back.');
    setAlertColor('#00b894');
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };
  // const capitalizedText = capitalizeFirstLetter(intertype);
  // const fullname = capitalizeFirstLetter(firstname);

  
  const getScheme = (id) => {
    fetch(`${API_BASE_URL}/supervisor/getschemedetail?regid=${id}`)
    .then(response => response.json())
    .then(data => {
      setScheme(data);
      showQuestion(true);
      })
    .catch(error => {
    
      const data = 
        {
            message: 'I am waiting for a notification to proceed with further action.',
         };
         setScheme(data);
      showQuestion(true);
    });
   
  }; 
  
  const handleAlertClick = (reg_id) => {
    setClickedAlert(reg_id);
    getScheme(reg_id);
  
  };
  return (
    <>
     {loading && <LoadingN />}
      <Header />
      <Sidemenu />
      <div className="page-content-wrapper">
      
        <div className="pt-3"></div>
        <div className="container">
          <div className="card card-bg-img bg-img bg-overlay mb-3" >
            <div className="card-body direction-rtl p-4">
              <h2 className="text-white">Hi {firstname}! {active=="Active" ?<i class="bi bi-shield-fill-check"></i>

            :
            <i class="bi bi-shield-fill-x"></i>
            }
             </h2>
              <p className="mb-4 text-white ">{intertype} |  {city}, {state}, {pincode} </p>
          


             
            </div>
          </div>
        </div>
        <div className="container direction-rtl">
      <div className="card mb-3" >
        <div className="card-body">
          <div className="row g-3">
            {featuresone.map((feature, index) => (
              <div  key={index}>
              <Link to={`/${feature.path}`} className="feature-card mx-auto text-center">
              <div className="single-plan-check shadow-sm active-effect ">
                <div className=" mb-0">
                <i className="bi bi-person-check text-primary fz-20 ms-auto"></i> <label className="" >{feature.text}</label>
                  </div>
                  {feature.countable}
                  </div>
                  </Link>
              </div>
            ))}

            
          </div>             
        </div>
      </div>
    </div>

<Container>
    <div className="element-heading mt-3">
        <h6>Cardholder Notification <i class="bi bi-bell-fill"></i><sup className='w-75 text-success h6'></sup></h6>
      </div>
      <Card>
        <Card.Body>
          <Tab.Container defaultActiveKey="All">
            <Nav variant="tabs" >
              <Nav.Item>
                <Nav.Link eventKey="All">Notification List </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="Read">Unread</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Pending">Read</Nav.Link>
              </Nav.Item> */}
            </Nav>

          
            <Tab.Content className="border-top-0">
              <Tab.Pane eventKey="All" >
              <div className="notification-area">
                  <Container>
                  <div className="pb-3"></div> 
                  {notifications.map((notification, index) => (
                <a onClick={() =>navigate('/Userlist', {
                  state: {
                    userid: notification.reg_id,
                    cid:notification.cid,
                    page: '/Home',
                  },
                }) } key={index} style={{ textDecoration: 'none' }}>
                <Alert
                  className={`custom-alert-3 alert-primary ${notification.unread === "yes" ? 'unread' : ''}`}
                  role="alert"
                
                >
                  <i className={`${notification.icon}`}   style={{ 
                    cursor: 'pointer',
                    color: clickedAlert === notification.reg_id ? '#f0f0f0' : '' // Change background color when clicked
                  }}/>
                  <div className="alert-text w-75">
                    <h6 className="text-truncate">{notification.title}</h6>
                    <strong className='w-75 text-success'>
                    {notification.schemeno === 0 ? 'No schemes applied |' : `${notification.schemeno} Scheme applied |`}  {notification.cardno === 0 ? 'No cards applied' : `${notification.cardno} Card applied`}
                  </strong>

                   <span className="text-truncate">{notification.message}</span>
           
            </div>
          </Alert>
        </a>
      ))}

                     <div className="pb-3"></div>
                  </Container>
               </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </Container>

    <div className="pb-3"></div>
        <Testimonialslider />
    
        <div className="pb-3"></div>
      </div>
      <Sheet isOpen={showConfirmation} onClose={() => showQuestion(false)}>
    <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
            <div className="p-4" style={{ height: 'auto', overflow: 'scroll' }}>
                
                <Table striped bordered hover>
                    <thead>
                  
                  
                    <tr>
                            <th colSpan={2}>  <a
                        className="blog-title text-success"
                        onClick={() =>
                          navigate('/yojanadetail', {
                            state: {
                              eligibility: showinsheet.eligibility,
                              exclusions: showinsheet.exclusions,
                              application_process: showinsheet.application_process,
                              documents_required: showinsheet.documents_required,
                              description: showinsheet.scheme_name,
                              details: showinsheet.details,
                              statelocation: showinsheet.state,
                              benefits: showinsheet.benefits,
                              yid:showinsheet.sid,
                              page: `Home?getid=${showinsheet.reg_id}`,
                            },
                          })
                        }
                      >
                       
                   
                            <i className="bi bi-bookmark-check-fill"></i> {showinsheet.scheme_name} <i class="bi bi-arrow-right-circle-fill"></i>
               </a> </th>
                           
                        </tr>
                        
                        <tr>
                            <th>Full Name</th>
                            <th>{showinsheet.firstname} {showinsheet.lastname}</th>
                        </tr>
                        <tr>
                            <th>State</th>
                            <th>{showinsheet.state || '----'}</th>
                        </tr>
                        <tr>
                            <th>City</th>
                            <th>{showinsheet.city || '----'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gender</td>
                            <td>{showinsheet.selectGender || '----'}</td>
                        </tr>
                        <tr>
                            <td>Age</td>
                            <td>{showinsheet.age || '----'}</td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>{showinsheet.mobile || '----'}</td>
                        </tr>
                        <tr>
                            <td>Relation Type</td>
                            <td>{showinsheet.relationType || '----'}</td>
                        </tr>
                        <tr>
                            <td>Marital Status</td>
                            <td>{showinsheet.maritalstatus || '----'}</td>
                        </tr>
                        <tr>
                            <td>Bank Account</td>
                            <td>{showinsheet.bank_account || '----'}</td>
                        </tr>
                        <tr>
                            <td>Monthly Income</td>
                            <td>{showinsheet.monthly_income || '----'}</td>
                        </tr>
                        <tr>
                            <td>Own Land</td>
                            <td>{showinsheet.own_land || '----'}</td>
                        </tr>
                        <tr>
                            <td>Ration Card Type</td>
                            <td>{showinsheet.ration_card_type || '----'}</td>
                        </tr>
                        <tr>
                            <td>Religion</td>
                            <td>{showinsheet.religion || '----'}</td>
                        </tr>
                        <tr>
                            <td>Caste</td>
                            <td>{showinsheet.caste || '----'}</td>
                        </tr>
                        <tr>
                            <td>Occupation</td>
                            <td>{showinsheet.occupation || '----'}</td>
                        </tr>
                        <tr>
                            <td>Nature Of Job</td>
                            <td>{showinsheet.nature_of_Job || '----'}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Sheet.Content>
    </Sheet.Container>
</Sheet>

      <HomeFooter />
      {showAlert && (
        <div id="internetStatus" style={{ display: 'block', backgroundColor: alertColor }}>
          {alertText}
        </div>
      )}
    </>
  );
};

export default Home;
