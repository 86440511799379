import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
const CreateUserprofile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const interntype = searchParams.get('interntype');
  const pincode = searchParams.get('pincode');
  const state = searchParams.get('state');
  const city = searchParams.get('city');
  const iframeSrc = `https://apply.aapkiyojana.org/Home?currentPage=${interntype}&state=${state}&city=${city}&pincode=${pincode}`;

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'https://apply.aapkiyojana.org') return;
    };
     window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'hidden', margin: 0, padding: 0 }}>
      <iframe
        src={iframeSrc}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          zIndex: 1,
        }}
        title="Cardholder Panel"
      ></iframe>
    </div>
  );
};

export default CreateUserprofile;
