import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from "./HomeFooter";
import { BrowserRouter, Routes, Route ,Router} from "react-router-dom";
const NoPage = () => {

 
  return (
   <>
  
  
      <div className="header-area" id="headerArea">
        <div className="container">
          <div className="header-content position-relative d-flex align-items-center justify-content-between">
            <div className="back-button">
       <Link to="/Home">
        <i className="bi bi-arrow-left-short"></i>
      </Link>
            </div>
            <div className="page-heading">
              <h6 className="mb-0">Page not found</h6>
            </div>
            <div className="setting-wrapper">
              <div className="setting-trigger-btn" id="settingTriggerBtn">
                <i className="bi bi-gear"></i>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

     

      <div className="page-content-wrapper py-3">
      <div className="custom-container">
        <div className="card">
          <div className="card-body px-5 text-center">
            <img className="mb-4" src="img/bg-img/39.png" alt="" />
            <h4>OOPS... <br /> Page not found!</h4>
            <p className="mb-4">We couldn't find any results for your search. Try again.</p>
            <Link className="btn btn-creative btn-danger" to="/home">Go to Home</Link>

          </div>
        </div>
      </div>
    </div>

   
 
   </>
  );
};

export default NoPage;
