import React, { useState ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Make sure to import Bootstrap CSS and JavaScript bundles correctly
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import NotificationExample, { notify } from './NotificationExample';
import { API_BASE_URL } from './Config';
import axios from 'axios';
const Otp = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setPhoneNumberError('');
  };
  useEffect(() => {
    const token = localStorage.getItem('mobile');
   if (token) {
    navigate('/Home');
    }
    
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();
 if (validatePhoneNumber(phoneNumber)) {
      try {
         const response = await axios.get(`${API_BASE_URL}supervisor/insertValidPhone/${phoneNumber}`);
        
        if (response.data.success==true) {
          navigate('/otpconfirm?phone='+phoneNumber);
        } else {
          notify('error', 'You are not registered as a supervisor. Please contact your branch to complete the registration process.');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
        
      }
   
    } else {
      setPhoneNumberError('Please enter a valid phone number');
    }
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(number);
  };

  return (
    <>
     <NotificationExample />
      <div className="login-back-button">
        {/* <Link to="/login">
          <i className="bi bi-arrow-left-short"></i>
        </Link> */}
      </div>
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <div className="text-center">
            {/* Assuming the image path is correct */}
            <img className="mx-auto mb-4 d-block" src="img/bg-img/36.png" alt="Background" />
            <h3>Phone Verification</h3>
            <p>We will send you an OTP code on this phone number.</p>
          </div>

          <div className="otp-form mt-4">
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
              
                <input
                  className={`form-control ${phoneNumberError ? 'is-invalid' : ''}`}
                  type="text"
                  name="phone"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  inputMode="numeric"
                />
                {phoneNumberError && <div className="invalid-feedback">{phoneNumberError}</div>}
              </div>
              <button className="btn btn-primary w-100" type="submit">Next</button>
            </form>
          </div>

          <div className="login-meta-data px-4 text-center">
            <p className="mt-3 mb-0">By providing my phone number, I hereby agree to the
              <Link to="/privacy-policy"> Terms of Service</Link> and <Link to="/privacy-policy"> Privacy Policy</Link>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
