// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('mobile');
   if (token) {
      setIsAuthenticated(true);
    }
     setLoading(false);
  }, []);

  const login = (userData) => {
    setLoading(true);
    localStorage.setItem('mobile', userData.mobile);
    localStorage.setItem('firstname', userData.staff_name);
    localStorage.setItem('photo', userData.staff_photo);
    localStorage.setItem('intertype', userData.name);
    localStorage.setItem('state', userData.staff_state);
    localStorage.setItem('city', userData.staff_city);
    localStorage.setItem('status', userData.active);
    localStorage.setItem('regid', userData.staff_id);
    localStorage.setItem('pincode', userData.staff_pincode);
    localStorage.setItem('welcomeToastShown', 'true');
    setIsAuthenticated(true);
    setLoading(false);
  };

  const logout = () => {
    setLoading(true);
    localStorage.removeItem('mobile');
    localStorage.removeItem('firstname');
    localStorage.removeItem('photo');
    localStorage.removeItem('intertype');
    localStorage.removeItem('state');
    localStorage.removeItem('city');
    localStorage.removeItem('status');
    localStorage.removeItem('regid');
    localStorage.removeItem('welcomeToastShown','false');
    localStorage.removeItem('pincode');
    setIsAuthenticated(false);
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
