import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (type, message) => {
  switch (type) {
    case 'success':
      toast.success(message, { style: { backgroundColor: '#1c5c2d',color:'#fff' } });
      break;
    case 'error':
      toast.error(message, { style: { backgroundColor: '#82242f',color:'#fff' } });
      break;
    case 'info':
      toast.info(message, { style: { backgroundColor: '#b39430',color:'#fff' } });
      break;
    default:
      toast(message);
  }
};

const NotificationExample = () => {
  return <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick={false}
ltl
pauseOnFocusLoss={false}
draggable
pauseOnHover={false}
/>
};

export default NotificationExample;
