import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/Otp'); // Ensure this path matches your login route
  }, [logout, navigate]);

  // Return null to render nothing
  return null;
};

export default Logout;



// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const Logout = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Clear user data from localStorage or sessionStorage
  
//       localStorage.removeItem('lcode');
//       localStorage.removeItem('mobile');
//       localStorage.removeItem('firstname');
//       localStorage.removeItem('lastname');
//       localStorage.removeItem('selectGender');
//       localStorage.removeItem('selectBusiness');
//       localStorage.removeItem('selectedDate');
//       localStorage.removeItem('age');
//       localStorage.removeItem('welcomeToastShown ');
     
//     // Redirect to login page
//     navigate('/Login'); // Ensure this path matches your login route
//   }, [navigate]);

//   // Return null to render nothing
//   return null;
// };

// export default Logout;
