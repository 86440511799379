import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect, useContext,useState } from 'react';

import Home from "./pages/Home";
import Login from "./pages/Login";
import Otp from "./pages/Otp";
import Qrcode from "./pages/Qrcode";
import NoPage from "./pages/NoPage";
import Yojanalist from "./pages/Yojanalist";
import Yojanadetail from "./pages/Yojanadetail";
import Bookmark from "./pages/Bookmark";
import Otpconfirm from "./pages/Otpconfirm";
import Language from "./pages/Language";
import Getstarted from "./pages/Getstarted";
import Global from './pages/Global';
import Registration from './pages/Registration';
import Profile from './pages/Profile';
import Logout from './pages/Logout';
import ProtectedRoute from './pages/ProtectedRoute';
import ProfileInfo from './pages/ProfileInfo';
import Changeprofile from './pages/Changeprofile';
import ChildProfile from './pages/ChildProfile';
import Mybooking from './pages/Mybooking';
import Qualifiedscheme from './pages/Qualifiedscheme';
import Completeprofile from './pages/Completeprofile';
import Msmescheme from './pages/Msmescheme';
import Congratulation from './pages/Congratulation';
import Supervisorlist from './pages/Supervisorlist';
import Userlist from './pages/Userlist';
import Editprofile from './pages/Editprofile';
import Address from './pages/Address';
import CreateUserprofile from './pages/CreateUserprofile';
import Createcard from './pages/Createcard';
import CardView from './pages/CardView';
import { AuthProvider, AuthContext } from './pages/AuthContext';
import Loading from './pages/Loader';

import { messaging, onMessage } from './pages/firebaseConfig';
import { getToken } from 'https://www.gstatic.com/firebasejs/9.17.2/firebase-messaging.js';

const App = () => {
  const [permissionGranted, setPermissionGranted] = useState(false);

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.log('Permission not granted for Notification');
        return;
      }

      // Register the service worker
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered with scope:', registration.scope);

      // Get FCM token
      const token = await getToken(messaging, { vapidKey: 'BDDYw9MsHj9uk32FO4XO6mPv0SWHItfyJpRSS9zAccrqw_ei43FchJ86Fiti4kj0KQJQHq1wvAYBIhO99jJ-7bE', serviceWorkerRegistration: registration });
      if (token) {
        console.log('FCM token:', token);
        // Send the token to your server or save it for later use
        setPermissionGranted(true);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving token. ', error);
    }
  };

  useEffect(() => {
    const handleMessage = (payload) => {
      console.log('Message received. ', payload);
      const { title, body } = payload.notification;
      new Notification(title, { body });
    };

    const unsubscribe = onMessage(messaging, handleMessage);

    return () => {
      unsubscribe();
    };
  }, []);
  return (
  
   
    <AuthProvider>
      <Router>
        <Global />
        <AuthContext.Consumer>
          {({ loading }) => (
            <>
              {loading && <Loading loading={loading} />}
              <Routes>
                <Route path="/" element={<Getstarted />} />
                <Route path="/Otp" element={<Otp />} />
                <Route path="/otpconfirm" element={<Otpconfirm />} />
                <Route path="/qrcode" element={<Qrcode />} />
                <Route path="/Registration" element={<Registration />} />

                <Route path="/" element={<ProtectedRoute />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/yojanalist" element={<Yojanalist />} />
                  <Route path="/Bookmark" element={<Bookmark />} />
                  <Route path="/yojanadetail" element={<Yojanadetail />} />
                  <Route path="/language" element={<Language />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/Changeprofile" element={<Changeprofile />} />
                  <Route path="/ChildProfile" element={<ChildProfile />} />
                  <Route path="/Mybooking" element={<Mybooking />} />
                  <Route path="/Qualifiedscheme" element={<Qualifiedscheme />} />
                  <Route path="/Completeprofile" element={<Completeprofile />} />
                  <Route path="/Congratulation" element={<Congratulation />} />
                  <Route path="/ProfileInfo" element={<ProfileInfo />} />
                  <Route path="/Msmescheme" element={<Msmescheme />} />
                  <Route path="/Supervisorlist" element={<Supervisorlist />} />
                  <Route path="/Userlist" element={<Userlist />} />
                  <Route path="/Editprofile" element={<Editprofile />} />
                  <Route path="/Address" element={<Address />} />
                  <Route path="/Createcard" element={<Createcard />} />
                  <Route path="/CardView" element={<CardView />} />
                  <Route path="/CreateUserprofile" element={<CreateUserprofile />} />
                  <Route path="/Logout" element={<Logout />} />
                </Route>

                <Route path="*" element={<NoPage />} />
              </Routes>
            </>
          )}
        </AuthContext.Consumer>
      </Router>
    </AuthProvider>

  );
};

export default App;
