import React, { useState } from 'react';

const ProfileInfo = () => {
  const titles = [
    'age',
    'applicationmode',
    'belowpovertyline',
    'benefittype',
    'caste',
    'dbtscheme',
    'differentlyabled',
    'disabilitypercentage',
    'economicdistress',
    'employmentstatus',
    'gender',
    'governmentemployee',
    'maritalstatus',
    'ministryname',
    'minority',
    'occupation',
    'residence',
    'schemecategory',
    'schemetype',
    'state',
    'storeScheme',
    'student'
  ];

  const [selectedTitle, setSelectedTitle] = useState('');

  const handleChange = (event) => {
    setSelectedTitle(event.target.value);
  };

  return (
    <div>
      <label htmlFor="title-select">Select a Title:</label>
      <select id="title-select" value={selectedTitle} onChange={handleChange}>
        <option value="" disabled>Select a title</option>
        {titles.map((title, index) => (
          <option key={index} value={title}>
            {title}
          </option>
        ))}
      </select>
      {selectedTitle && <p>You selected: {selectedTitle}</p>}
    </div>
  );
};

export default ProfileInfo;
