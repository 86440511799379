// src/firebaseConfig.js
import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-app.js";
import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-analytics.js";
import { getAuth } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";
import { getMessaging, onMessage } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-messaging.js";


const firebaseConfig = {
  apiKey: "AIzaSyCuAbxwXwUmEY3VwdTmiBrhNUrlqEjs75Y",
  authDomain: "appki-yojana-web-app.firebaseapp.com",
  projectId: "appki-yojana-web-app",
  storageBucket: "appki-yojana-web-app.appspot.com",
  messagingSenderId: "1024353243074",
  appId: "1:1024353243074:web:38d7fb9f1929256856905b",
  measurementId: "G-NN9XY761ZN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const messaging = getMessaging(app);

export { app, analytics, auth, messaging, onMessage };
