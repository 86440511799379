import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from "./HomeFooter";
import LoadingN from './Loader';
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';

const Yojanalist = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('scheme_name');
  const [bookmarkedSchemes, setBookmarkedSchemes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight - 100 && !loading) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);

  const fetchData = async (page) => {
    setLoading(true);
    try {
     var state=localStorage.getItem('state');
      const response = await fetch(`${API_BASE_URL}/cpanel/supervisorapi?state=${state}&page=${page}`);
      const result = await response.json();
      setData(prevData => [...prevData, ...result]);
     // notify('success', 'Data fetched successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
      notify('error', 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleBookmark = async (id) => {
    try {
      const userid = localStorage.getItem('regid');
      if (!userid) {
        notify('error', 'You are not authorized to access the saved scheme. Please re-login to access the saved scheme.');
        return;
      }
      const response = await fetch(`${API_BASE_URL}/cpanel/bookmark`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ schemeId: id, userId: userid }),
      });

      if (response.ok) {
        setBookmarkedSchemes(prev => [...prev, id]);
        notify('success', 'Your scheme has been saved successfully.');
      } else {
        console.error('This scheme has already been saved successfully.');
        notify('error', 'This scheme has already been saved successfully.');
      }
    } catch (error) {
      console.error('Error bookmarking scheme:', error);
      notify('error', 'Error bookmarking scheme');
    }
  };

  const filteredData = data.filter(scheme => {
    const searchValue = searchTerm.toLowerCase();
    return filterBy === 'scheme_name'
      ? scheme.scheme_name.toLowerCase().includes(searchValue)
      : scheme.state.toLowerCase().includes(searchValue);
  });

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };
  const renderStates = (states) => {
    const stateList = states.split(','); 
    if (stateList.length > 2) {
        return 'All State';
    } else {
        return states;
    }
  };
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  
  const truncateTexttext = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };
  
  return (
    <>
      <Settingpop />
      <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Home">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Recommended Schemes</h2>
              </div>
              <div className="setting-wrapper">
                <div className="setting-trigger-btn" id="settingTriggerBtn">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                  </Link>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content-wrapper py-3">
          <div className="container">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-3 position-relative">
              <input
                type="text"
                className="form-control px-5"
                placeholder="Search by scheme name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
              {searchTerm && (
                <i
                  className="bi bi-x-circle-fill position-absolute top-50 end-0 translate-middle-y me-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSearchTerm('')}
                ></i>
              )}
            </div>
            {loading && <LoadingN />}
            <div className="row g-3 justify-content-center">
         
              {filteredData.map((scheme, index) => (
                <div key={index} className="col-12 col-md-8 col-lg-7 col-xl-6">
                  <div className="card shadow-sm blog-list-card">
                    <div className="card-blog-content">
                      <span className="badge bg-success rounded-pill mb-2 d-inline-block">{renderStates(scheme.state)}</span>
                      <i
                        className={`bi ${bookmarkedSchemes.includes(scheme.sid) ? 'bi-bookmark-fill' : 'bi-bookmark'}`}
                        style={iconStylesIcon}
                        onClick={() => handleBookmark(scheme.sid)}
                      ></i>
                      <a
                        className="blog-title d-block text-dark"
                        onClick={() =>
                          navigate('/yojanadetail', {
                            state: {
                              eligibility: scheme.eligibility,
                              exclusions: scheme.exclusions,
                              application_process: scheme.application_process,
                              documents_required: scheme.documents_required,
                              description: scheme.scheme_name,
                              details: scheme.details,
                              statelocation: scheme.state,
                              benefits: scheme.benefits,
                              yid:scheme.sid,
                              page: 'Yojanalist',
                            },
                          })
                        }
                      >
                        {scheme.scheme_name}
                      </a>
                      <div>{truncateTexttext(stripHtmlTags(scheme.details), 100)}</div>
                      <span className="badge rounded-pill text-bg-light" style={{color:'gray'}}>{scheme.allschemes}</span>
                      <a
                        className="float-end"
                        onClick={() =>
                          navigate('/yojanadetail', {
                            state: {
                              eligibility: scheme.eligibility,
                              exclusions: scheme.exclusions,
                              application_process: scheme.application_process,
                              documents_required: scheme.documents_required,
                              description: scheme.scheme_name,
                              details: scheme.details,
                              statelocation: scheme.state,
                              benefits: scheme.benefits,
                              yid:scheme.sid,
                              page: 'Yojanalist',
                            },
                          })
                        }
                      >  
                        <i className="bi bi-arrow-right-circle-fill" style={iconStyles}></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
           
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

const iconStyles = {
  color: 'green',
  fontSize: '24px',
};

const iconStylesIcon = {
  color: 'gray',
  fontSize: '24px',
  cursor: 'pointer',
  float: 'right',
};

export default Yojanalist;
