// src/SettingsPopup.js
import React, { useEffect ,useState} from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { BrowserRouter, Routes, Route ,Router} from "react-router-dom";
const Settingpop = () => {
  const [availabilityStatus, setAvailabilityStatus] = useState(true);
  const [sendMeNotifications, setSendMeNotifications] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [rtlMode, setRtlMode] = useState(false);

  const handleAvailabilityChange = () => setAvailabilityStatus(!availabilityStatus);
  const handleNotificationsChange = () => setSendMeNotifications(!sendMeNotifications);
  const handleDarkModeChange = () => setDarkMode(!darkMode);
  const handleRtlModeChange = () => setRtlMode(!rtlMode);

  return (
    <div>
      <div className="dark-mode-switching">
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <div className="dark-mode-text text-center">
            <i className="bi bi-moon"></i>
            <p className="mb-0">Switching to dark mode</p>
          </div>
          <div className="light-mode-text text-center">
            <i className="bi bi-brightness-high"></i>
            <p className="mb-0">Switching to light mode</p>
          </div>
        </div>
      </div>

      <div className="rtl-mode-switching">
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <div className="rtl-mode-text text-center">
            <i className="bi bi-text-right"></i>
            <p className="mb-0">Switching to RTL mode</p>
          </div>
          <div className="ltr-mode-text text-center">
            <i className="bi bi-text-left"></i>
            <p className="mb-0">Switching to default mode</p>
          </div>
        </div>
      </div>

      <div id="setting-popup-overlay"></div>

      <div className="card setting-popup-card shadow-lg" id="settingCard">
        <div className="card-body">
          <div className="container">
            <div className="setting-heading d-flex align-items-center justify-content-between mb-3">
              <p className="mb-0">Settings</p>
              <div className="btn-close" id="settingCardClose"></div>
            </div>

            <div className="single-setting-panel">
              <div className="form-check form-switch mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="availabilityStatus"
                  checked={availabilityStatus}
                  onChange={handleAvailabilityChange}
                />
                <label className="form-check-label" htmlFor="availabilityStatus">
                  Availability status
                </label>
              </div>
            </div>

            <div className="single-setting-panel">
              <div className="form-check form-switch mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sendMeNotifications"
                  checked={sendMeNotifications}
                  onChange={handleNotificationsChange}
                />
                <label className="form-check-label" htmlFor="sendMeNotifications">
                  Send me notifications
                </label>
              </div>
            </div>

            <div className="single-setting-panel">
              <div className="form-check form-switch mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="darkSwitch"
                  checked={darkMode}
                  onChange={handleDarkModeChange}
                />
                <label className="form-check-label" htmlFor="darkSwitch">
                  Dark mode
                </label>
              </div>
            </div>

            <div className="single-setting-panel">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rtlSwitch"
                  checked={rtlMode}
                  onChange={handleRtlModeChange}
                />
                <label className="form-check-label" htmlFor="rtlSwitch">
                  RTL mode
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settingpop;
