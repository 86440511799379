import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Table } from 'react-bootstrap';
import { Sheet } from 'react-modal-sheet';
import Header from "./Header";
import Sidemenu from "./Sidemenu";
import HomeFooter from "./HomeFooter";
import Testimonialslider from "./Testimonialslider";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'tiny-slider/src/tiny-slider.scss';
import './App.css';
import { API_BASE_URL } from './Config';
import ReactPaginate from 'react-paginate';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPreloader, setShowPreloader] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [firstname, setFrstname] = useState('');
  const [mobile, setMobile] = useState('');
  const [image, setImage] = useState('');
  const [intertype, setIntertype] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [active, setActive] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [featuresone, setFeaturesone] = useState([]);
  const [showConfirmation, showQuestion] = useState(false);
  const [showinsheet, setShowinsheet] = useState({});
  const [clickedAlert, setClickedAlert] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [ppincode, setPpincode] = useState(0);
  const itemsPerPage = 10; // Number of items per page
  const [searchQuery, setSearchQuery] = useState(''); // State to track the search query
  const { userid ,cid} = location.state || {};
 
  const statuschange = (id) => {
    fetch(`${API_BASE_URL}/supervisor/getbenificialShowDetailstatuschange?regid=${id}`)
    .then(response => response.json())
    .then(data => {})
    .catch(error => {
    console.error('Error fetching notifications:', error);
     });
   
  }; 
 
  useEffect(() => {
    
  if(!userid)
  {

  
    const searchParams = new URLSearchParams(location.search);
    const getid = searchParams.get('getid');
    const pincode = searchParams.get('pincode');
   
    if(localStorage.getItem('pincode')!=pincode){
      return false;
      }
    if (getid && getid.trim() !== "") {
      handleAlertClick(getid);
    }
    setPpincode(pincode);
    const firstname = localStorage.getItem('firstname');
    const photo = localStorage.getItem('photo');
    const intertype = localStorage.getItem('intertype');
    const state = localStorage.getItem('state');
    const city = localStorage.getItem('city');
    const status = localStorage.getItem('status');
    const mobile = localStorage.getItem('mobile');
    const regid = localStorage.getItem('regid');
   
    setFrstname(firstname);
    setMobile(mobile);
    setImage(photo);
    setIntertype(intertype);
    setState(state);
    setCity(city);
    setActive(status);
  
    fetch(`${API_BASE_URL}supervisor/getUserlist?interntype=${intertype}&state=${state}&city=${city}&pincode=${pincode}`)
      .then(response => response.json())
      .then(data => {
        setNotifications(data);
      })
      .catch(error => {
        const data = [
          {
            title: 'No data available',
            message: 'I am waiting for a notification to proceed with further action.',
          }
        ];
        setNotifications(data);
        console.error('Error fetching notifications:', error);
      });
    }else{
      statuschange(cid);
      fetch(`${API_BASE_URL}supervisor/SinglegetUserlist?userid=${userid}`)
      .then(response => response.json())
      .then(data => {
        setNotifications(data);
      })
      .catch(error => {
        const data = [
          {
            title: 'No data available',
            message: 'I am waiting for a notification to proceed with further action.',
          }
        ];
        setNotifications(data);
        console.error('Error fetching notifications:', error);
      });
    }


  }, [location.search]); 

  const handleOfflineClick = () => {
    setAlertText('Oops! No internet connection.');
    setAlertColor('#ea4c62');
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleOnlineClick = () => {
    setAlertText('Your internet connection is back.');
    setAlertColor('#00b894');
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const capitalizedText = capitalizeFirstLetter(intertype);
  const fullname = capitalizeFirstLetter(firstname);

  const checkEligibility = (id) => {
    fetch(`${API_BASE_URL}/supervisor/getbenificialShowDetail?regid=${id}`)
      .then(response => response.json())
      .then(data => {
        setShowinsheet(data);
        showQuestion(true);
      })
      .catch(error => {
        const data = {
          message: 'I am waiting for a notification to proceed with further action.',
        };
        setShowinsheet(data);
        console.error('Error fetching notifications:', error);
        showQuestion(true);
      });
  };

  const handleAlertClick = (reg_id) => {
    setClickedAlert(reg_id);
    checkEligibility(reg_id);
  };

  const handleAlertClickIndex = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the accordion if already open
    } else {
      setOpenIndex(index); // Open the accordion
    }
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * itemsPerPage;
  const filteredNotifications = notifications.filter(notification => 
    notification.title.toLowerCase().includes(searchQuery.toLowerCase()) || notification.mobile && notification.mobile.includes(searchQuery) ||  notification.state.toLowerCase().includes(searchQuery.toLowerCase())||  notification.city.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = filteredNotifications.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredNotifications.length / itemsPerPage);

  const handleAddProfile = () => {
    const searchParams = new URLSearchParams(location.search);
    const pincode = searchParams.get('pincode');
    navigate(`/CreateUserprofile?interntype=${intertype}&state=${state}&city=${city}&pincode=${pincode}`); 
  };
const editProfile=(id)=>{
  navigate(`/Address?getid=${id}&state=${state}&city=${city}`);
  
}
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };


  const handleNavigate = (id,firstname,lastname) => {

    const data = {
      regid:id,
      firstname:firstname,
      lastname:lastname,
      pincode:ppincode,
      intertype:intertype
    };
  
    navigate('/Createcard', { state: { data } });
  };
  return (
    <>
      <div className="header-area" id="headerArea">
        <div className="container">
          <div className="header-content position-relative d-flex align-items-center justify-content-between">
            <div className="back-button">
              <Link to="/Home">
                <i className="bi bi-arrow-left-short"></i>
              </Link>
            </div>
            <div className="page-heading">
              <h2 className="mb-0">Cardholder List</h2>
            </div>
            <div className="setting-wrapper d-flex align-items-center">
              <div className="setting-trigger-btn" id="settingTriggerBtn">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                </Link>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper">
        <div className="pt-3 d-block mt-5 mb-2">
          <button className="btn btn-primary w-100" onClick={handleAddProfile}>
            <i className="bi bi-plus-circle"></i>  Add a Profile
          </button>
        </div>
        <div className="card-body p-2">
          <div className="chat-search-box">
            {/* Search Form */}
            <form action="#">
              <div className="input-group">
                <span className="input-group-text" id="searchbox">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  className="form-control form-control-clicked"
                  type="search"
                  placeholder="Search users or messages"
                  aria-describedby="searchbox"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </form>
          </div>
        </div>
        <ul className="ps-0 chat-user-list">
          {currentItems.map((notification, index) => {
                 const randomColor = getRandomColor();
                 return(
            <li key={notification.reg_id} className="p-3">
              
              <div className="chat-user-thumbnail me-3 shadow d-flex align-items-center justify-content-center">
                <span className="img-circle" style={{ color:'#fff',display: 'inline-flex', width: '40px', height: '36px', borderRadius: '50%', backgroundColor: randomColor, alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                  {notification.title.charAt(12)}
                </span>
              </div>
              <div className="chat-user-info">
                <a className="d-flex" onClick={() => handleAlertClick(notification.reg_id)} style={{ cursor: 'pointer' }}> 
                  <h6 className="text-truncate mb-0">{notification.title}</h6>
                </a>
                <div className="last-chat">
                  <p className="mb-0 text-gray">
                    {notification.message} {notification.childno && (  
                      <span>
                        <button className="btn btn-link text-decoration-none" type="button" onClick={(e) => { e.stopPropagation(); handleAlertClickIndex(index); }}>
                          <span className="badge rounded-pill bg-primary">{notification.childno}</span>
                        </button>
                        <div className={`collapse mt-1 ${openIndex === index ? 'show' : ''}`}>
                          <div className="card card-body">
                       
                            {notification.child.map((child, childIndex) => (
                              <div key={childIndex}>
                                <p className="mb-0 text-truncate">Name: {child.firstname} Age: {child.age} Relation: {child.relationType}     
                                 
                                </p>
                                <span className="badge fw-normal text-success" onClick={() => handleAlertClick(child.reg_id)} style={{ cursor: 'pointer', fontSize:'20px' }}>
                                    <i className="bi bi-eye-fill"></i>
                                  </span> 
                                   <span className="badge fw-normal text-primary" onClick={() => editProfile(child.reg_id)} style={{ cursor: 'pointer',fontSize:'20px' }}>
                                <i className="bi bi-pencil-square"></i>
                                 </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div className="dropstart chat-options-btn">
              <span className="badge fw-normal text-primary" onClick={() => editProfile(notification.reg_id)} style={{ cursor: 'pointer',fontSize:'20px' }}>
                    <i className="bi bi-pencil-square"></i>
                  </span>
                
              </div>
            </li>
                 );
            })}
        </ul>

        <div className="pt-3"></div>
      </div>
      <Sheet isOpen={showConfirmation} onClose={() => showQuestion(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="p-4" style={{ height: 'auto', overflow: 'scroll' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <th colSpan={2}>
                      <a
                        className="blog-title text-success"
                        onClick={() =>
                          navigate('/yojanadetail', {
                            state: {
                              eligibility: showinsheet.eligibility,
                              exclusions: showinsheet.exclusions,
                              application_process: showinsheet.application_process,
                              documents_required: showinsheet.documents_required,
                              description: showinsheet.scheme_name,
                              details: showinsheet.details,
                              statelocation: showinsheet.state,
                              benefits: showinsheet.benefits,
                              yid: showinsheet.sid,
                              page: `Home?getid=${showinsheet.reg_id}`,
                            },
                          })
                        }
                      >
                        <i className="bi bi-bookmark-check-fill"></i> {showinsheet.scheme_name} <i className="bi bi-arrow-right-circle-fill"></i>
                      </a>
                    </th> */}
                  </tr>
                  <tr>
                    <th>Full Name</th>
                    <th>{showinsheet.firstname} {showinsheet.lastname}</th>
                  </tr>
                  <tr>
                    <th>State</th>
                    <th>{showinsheet.state || '----'}</th>
                  </tr>
                  <tr>
                    <th>City</th>
                    <th>{showinsheet.city || '----'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Gender</td>
                    <td>{showinsheet.selectGender || '----'}</td>
                  </tr>
                  <tr>
                    <td>Age</td>
                    <td>{showinsheet.age || '----'}</td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>{showinsheet.mobile || '----'}</td>
                  </tr>
                  <tr>
                    <td>Relation Type</td>
                    <td>{showinsheet.relationType || '----'}</td>
                  </tr>
                  <tr>
                    <td>Marital Status</td>
                    <td>{showinsheet.maritalstatus || '----'}</td>
                  </tr>
                  <tr>
                    <td>Bank Account</td>
                    <td>{showinsheet.bank_account || '----'}</td>
                  </tr>
                  <tr>
                    <td>Monthly Income</td>
                    <td>{showinsheet.monthly_income || '----'}</td>
                  </tr>
                  <tr>
                    <td>Own Land</td>
                    <td>{showinsheet.own_land || '----'}</td>
                  </tr>
                  <tr>
                    <td>Ration Card Type</td>
                    <td>{showinsheet.ration_card_type || '----'}</td>
                  </tr>
                  <tr>
                    <td>Religion</td>
                    <td>{showinsheet.religion || '----'}</td>
                  </tr>
                  <tr>
                    <td>Caste</td>
                    <td>{showinsheet.caste || '----'}</td>
                  </tr>
                  <tr>
                    <td>Occupation</td>
                    <td>{showinsheet.occupation || '----'}</td>
                  </tr>
                  <tr>
                    <td>Nature Of Job</td>
                    <td>{showinsheet.nature_of_Job || '----'}</td>
                  </tr>
                </tbody>
              </Table>
              <button onClick={()=>handleNavigate(showinsheet.reg_id,showinsheet.firstname,showinsheet.lastname)} class="btn btn-success">Get Your Card and Scheme</button>
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
      <HomeFooter />
      {showAlert && (
        <div id="internetStatus" style={{ display: 'block', backgroundColor: alertColor }}>
          {alertText}
        </div>
      )}
    </>
  );
};

export default Home;
