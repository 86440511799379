import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import HomeFooter from "./HomeFooter";
import NotificationExample, { notify } from './NotificationExample';
import LoadingN from './Loader';
import { API_BASE_URL } from './Config';

const Completeprofile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    maritalstatus: '',
    bank_account: '',
    monthly_income: '',
    own_land: '',
    ration_card_type: '',
    religion: '',
    caste: '',
    nature_of_Job: '',
    occupation: '',
    state: ''
  });

  const [selectOptions, setSelectOptions] = useState({
    maritalstatus: [],
    bank_account: [],
    monthly_income: [],
    own_land: [],
    ration_card_type: [],
    religion: [],
    caste: [],
    nature_of_Job: [],
    occupation: [],
    state: [],
  });

  useEffect(() => {
    const titles = [
      'maritalstatus',
      'bank_account',
      'monthly_income',
      'own_land',
      'ration_card_type',
      'religion',
      'caste',
      'nature_of_Job',
      'occupation',
      'state',
    ];

    titles.forEach((title) => {
      const storedValue = localStorage.getItem(title);
      if (storedValue) {
        setProfileData((prevData) => ({ ...prevData, [title]: storedValue }));
      }
    });

    // Fetch select options from the API
    titles.forEach(async (title) => {
      try {
        const response = await fetch(`${API_BASE_URL}cpanel/getAllMaster/${title}`);
        const data = await response.json();
        setSelectOptions((prevOptions) => ({ ...prevOptions, [title]: data }));
      } catch (error) {
        console.error(`Error fetching ${title} data:`, error);
      }
    });
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [id]: value }));
    localStorage.setItem(id, value); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const reg_id = localStorage.getItem('regid');
    const dataToSubmit = { ...profileData, reg_id };

    try {
      const response = await fetch(`${API_BASE_URL}cpanel/saveProfileData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSubmit)
      });
      const result = await response.json();
      if (result.Status) {
        notify('success', 'Your scheme has been saved successfully.');
        navigate('/Congratulation');
      } else {
        setLoading(false);
        notify('error', result.message);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting profile data:', error);
    }
  };

  const labelMapping = {
    maritalstatus: 'Marital status',
    bank_account: 'Do You Have a Bank Account?',
    monthly_income: 'Monthly income',
    own_land: 'Do you or any of your family members own any land?',
    ration_card_type: 'Ration card type',
    religion: 'What is your religion?',
    caste: 'Caste Category',
    nature_of_Job: 'Nature of Job',
    occupation: 'What is your present occupational status?',
    state: 'State',
  };

  return (
    <>
      <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Home">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Profile</h2>
              </div>
              <div className="setting-wrapper">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingN />}
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6>Complete Profile to check eligibility</h6>
          </div>
        </div>

        <div className="container">
          <div className="card">
            <div className="card-body">
              <style>
                {`
                  .custom-select-wrapper {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                  }

                  .custom-select {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    padding-left: 30px;
                    width: 100%;
                    cursor: pointer;
                  }

                  .custom-select::after {
                    content: '▼';
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                    color: #333;
                  }
                `}
              </style>
              <form onSubmit={handleSubmit}>
                {Object.keys(profileData).map((title) => (
                  <div className="form-group" key={title}>
                    <label className="form-label" htmlFor={title}>{labelMapping[title] || title}</label>
                    <div className="custom-select-wrapper">
                      <select
                        className="form-control custom-select"
                        id={title}
                        value={profileData[title]}
                        onChange={handleChange}
                      >
                        <option value="">Select {labelMapping[title] || title}</option>
                        {Array.isArray(selectOptions[title]) && selectOptions[title].map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
                <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                  Complete your profile <i className="bi bi-arrow-right fz-16 ms-1"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Completeprofile;
