import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import HomeFooter from "./HomeFooter";
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import LoadingN from './Loader';
import axios from 'axios';

const Address = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const [adhaar, setAdhaar] = useState('');
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState('');
  const [cardfee, setCardfee] = useState(0);
  const [regid, setRegid] = useState('');
  const [pincode, setPincode] = useState('');
  const [inter, setInter] = useState('');
  const [applied, setApplied] = useState([]);
  const [selectedFees, setSelectedFees] = useState([]); // To store selected fees
  const [totalFee, setTotalFee] = useState(0); // Initialize with the card fee
  const [cardNumberStatus, setCardNumberStatus] = useState(1);
  const [validationMessage, setValidationMessage] = useState(''); // State for validation message
  const [editUser, setEditUser] = useState('');

  useEffect(() => {
    if (data) {
      setAdhaar(data.adhaar || '');
      setNumber(data.cardNumber || '');
      setAddress(data.address || '');
      setRegid(data.regid);
      setPincode(data.pincode);
      setInter(data.intertype);
    }
    fetchData(data.regid);
    editStatus(data.regid);
  }, [data]);

  const editStatus = async (userid) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cpanel/userCheckCardStatus?userid=${userid}`);
      const result = response.data;
      setAdhaar(result.adhaar);
      setAddress(result.address);
      setNumber(result.cardNumber);
      setEditUser(result.cardNumber);

      if (result.cardNumber !== "" && result.cardNumber !== null) {
        setTotalFee((prevTotal) => prevTotal - cardfee);
        setCardfee(0);
       
      } else {
        setCardfee(199);
        setTotalFee(199);
     
      }
    } catch (error) {
      console.log('Error checking card number');
    }
  };

  const checkCardNumberStatus = async (cardNumber) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/cpanel/checkCardNumber?card=${cardNumber}`);
      const result = response.data;
      setCardNumberStatus(result.exists);
    } catch (error) {
      setCardNumberStatus('false');
      notify('error', 'Error checking card number');
    }
  };

  const handleCardNumberChange = (e) => {
    const cardNumber = e.target.value;
    setNumber(cardNumber);
    checkCardNumberStatus(cardNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFees.length === 0) {
      setValidationMessage('Please select at least one fee option.');
      return;
    }
    setValidationMessage('');
if (cardNumberStatus === 0) {
      notify('error', 'Enter a card number that has been approved.');
      return;
    }
    if (cardNumberStatus === 2) {
      notify('error', 'Card number already allotted. Please try another one.');
      return;
    }
    setLoading(true);

    const profileData = {
      regid: regid,
      adhaar,
      cardNumber: number,
      address,
      cardfee: totalFee,
      appliedSchemes: selectedFees,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/cpanel/saveCardDetail`, profileData);
      const result = response.data;
      if (result.Status) {
        localStorage.setItem('cardId', regid);
        navigate('/CardView');
      } else {
        notify('error', result.message);
      }
    } catch (error) {
      notify('error', 'Error submitting profile data');
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (userid) => {
   
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/cpanel/getAppliedScheme?userid=${userid}`);
      const result = await response.json();
      setApplied(result);

      const initiallySelectedFees = result.filter(item => item.status !== 'checked').map(item => ({
        fee: item.fee,
        sid: item.sid
      }));
      setSelectedFees(initiallySelectedFees);
      calculateTotalFee(initiallySelectedFees);
    } catch (error) {
      console.error('Error fetching data:', error);
      notify('error', 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (fee, checked, sid) => {
    let updatedFees;
    const alreadySelected = selectedFees.some(item => item.sid === sid);
    if (checked && !alreadySelected) {
      updatedFees = [...selectedFees, { fee, sid }];
    } else if (!checked && alreadySelected) {
      updatedFees = selectedFees.filter(item => item.sid !== sid);
    } else {
      updatedFees = selectedFees;
    }
    setSelectedFees(updatedFees);
    calculateTotalFee(updatedFees);
  };

  const calculateTotalFee = (fees) => {
    const total = fees.reduce((sum, fee) => sum + parseInt(fee.fee), cardfee);
     setTotalFee(total);
  };

  const isFeeSelected = (sid) => selectedFees.some(item => item.sid === sid);

  return (
    <>
      <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <a onClick={() => navigate('/Userlist', {
                  state: {
                    userid: regid,
                    cid: 0,
                    page: '/Home',
                  },
                })}>
                  <i className="bi bi-arrow-left-short"></i>
                </a>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Generate Card</h2>
              </div>
              <div className="setting-wrapper">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h3 className='text-success'><i className="bi bi-person-vcard"></i> {data.firstname} {data.lastname}</h3>
          </div>
        </div>
        {loading && <LoadingN />}
        <div className="container">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="adhaar">Adhaar Number</label>
                  <input
                    className="form-control"
                    id="adhaar"
                    type="number"
                    value={adhaar}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 12) {
                        setAdhaar(inputValue);
                      }
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="address">Address</label>
                  <input
                    className="form-control"
                    id="address"
                    type="text"
                    value={address}
                    maxLength="100"
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="cardNumber">Card Number</label>
                  <input
                    className="form-control"
                    id="cardNumber"
                    type="text"
                    value={number}
                    maxLength="10"
                    onKeyUp={handleCardNumberChange}
                    onChange={(e) => setNumber(e.target.value)}
                    required
                  />
                  <div className="text-danger mt-1">
                    {cardNumberStatus === 0 && (
                      <span>Enter a card number that has been approved.</span>
                    )}
                    {cardNumberStatus === 2 && (
                      <span>Card number already allotted. Please try another one.</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label key="cardFeeCheckbox" className="list-group-item" htmlFor="cardFeeCheckbox">
<i className="bi bi-currency-rupee"></i> 199 - Card Fee
                  </label>
                </div>
                <div className="element-heading mt-3">
                  <h6>Applied Scheme List</h6>
                </div>
                <div className="list-group">
                  {applied.map((item, index) => (
                    <label key={index} className="list-group-item" htmlFor={`listCheckbox${index}`}>
                      {item.status !== "checked" && (
                        <input
                          className="form-check-input me-2"
                          id={`listCheckbox${index}`}
                          type="checkbox"
                          value={item.fee}
                          checked={isFeeSelected(item.sid)}
                          onChange={(e) => handleCheckboxChange(item.fee, e.target.checked, item.sid)}
                        />
                      )}
                      <i className="bi bi-currency-rupee"></i> {item.fee} - {item.scheme_name}
                    </label>
                  ))}
                </div>
                <br />

                {validationMessage && (
                  <div className="alert alert-danger" role="alert">
                    {validationMessage}
                  </div>
                )}

                <div className="form-group">
                  <label className="form-label">
                    Please note that the price mentioned is specifically for the card, which is <strong>{totalFee} <i className="bi bi-currency-rupee"></i></strong>
                  </label>
                </div>
                <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                  Pay Now ({totalFee} <i className="bi bi-currency-rupee"></i>)
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Address;
