import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { API_BASE_URL } from './Config';

const Registration = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      termsAccepted: false,
      selectGender: '',
      selectBusiness: '',
      selectedDate: '',
      age: '',
      mobile: ''
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('Enter first name.'),
      lastname: Yup.string().required('Enter last name'),
      termsAccepted: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
      selectGender: Yup.string().required('Select gender'),
      selectBusiness: Yup.string().required('Select business type'),
      selectedDate: Yup.date().required('Enter your date of birth.'),
      age: Yup.number().required('').min(18, 'Age must be greater than 18'),
    }),
    
    onSubmit: async (values) => {
      // Format the values before submission
      const formattedValues = {
        ...values,
        firstname: formatText(values.firstname),
        lastname: formatText(values.lastname),
      };

      await axios.post(API_BASE_URL + 'cpanel/registration', formattedValues)
        .then(response => {
         
          if (response.data.success) {
         
            localStorage.setItem('firstname', formattedValues.firstname);
            localStorage.setItem('lastname', formattedValues.lastname);
            localStorage.setItem('selectGender',formattedValues.selectGender);
            localStorage.setItem('selectBusiness',formattedValues.selectBusiness);
            localStorage.setItem('selectedDate',formattedValues.selectedDate);
            localStorage.setItem('age', formattedValues.age);
            localStorage.setItem('mobile', formattedValues.mobile);
            localStorage.setItem('userId', response.data.user_id);
            localStorage.setItem('regid', response.data.reg_id);
             navigate('/Home');
          } else {
            alert("Registration submission failed. Please try again.");
          }
        })
        .catch(error => console.error('Error fetching data: ', error));
    },
  });

  const formatText = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateBirthDate = (age) => {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    let birthDate = new Date(today.setFullYear(birthYear));
    return birthDate.toISOString().split('T')[0];
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const phone = searchParams.get('phone');

    setPhoneNumber(phone);
    formik.setFieldValue('mobile', phone); // Set phone number in formik's mobile field
    if (formik.values.age) {
      const birthDate = calculateBirthDate(formik.values.age);
      formik.setFieldValue('selectedDate', birthDate);
    }
  }, [location.search, formik.values.age]);

  useEffect(() => {
    if (formik.values.selectedDate) {
      const birthDate = new Date(formik.values.selectedDate);
      const age = calculateAge(birthDate);
      formik.setFieldValue('age', age);
    }
  }, [formik.values.selectedDate]);

  return (
    <>
      <div className="login-back-button">
        <Link to="/Login">
          <i className="bi bi-arrow-left-short"></i>
        </Link>
      </div>
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <div className="text-center px-4">
            <img className="login-intro-img" src="img/bg-img/36.png" alt="" />
          </div>
          <div className="register-form mt-4">
            <h6 className="mb-3 text-center">Register to continue to {phoneNumber}</h6>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center">
                <div className="form-group me-3">
                  <label className="form-label">First Name</label>
                  <input
                    className="form-control"
                    id="firstname"
                    type="text"
                    {...formik.getFieldProps('firstname')}
                    value={formatText(formik.values.firstname)}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="text-danger">{formik.errors.firstname}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    className="form-control"
                    id="lastname"
                    type="text"
                    {...formik.getFieldProps('lastname')}
                    value={formatText(formik.values.lastname)}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="text-danger">{formik.errors.lastname}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Gender</label>
                <select
                  className="form-select form-control-clicked"
                  id="selectGender"
                  {...formik.getFieldProps('selectGender')}
                >
                  <option value="">--Select--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {formik.touched.selectGender && formik.errors.selectGender ? (
                  <div className="text-danger">{formik.errors.selectGender}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">Are you business man?</label>
                <select
                  className="form-select form-control-clicked"
                  id="selectBusiness"
                  {...formik.getFieldProps('selectBusiness')}
                >
                  <option value="">--Select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {formik.touched.selectBusiness && formik.errors.selectBusiness ? (
                  <div className="text-danger">{formik.errors.selectBusiness}</div>
                ) : null}
              </div>
              <div className="d-flex align-items-center">
                <div className="form-group me-1">
                  <label className="form-label" htmlFor="inputDate">Date of birth</label>
                  <input
                    className="form-control form-control-clicked"
                    id="inputDate"
                    type="date"
                    {...formik.getFieldProps('selectedDate')}
                  />
                  {formik.touched.selectedDate && formik.errors.selectedDate ? (
                    <div className="text-danger">{formik.errors.selectedDate}</div>
                  ) : null}
                </div>
                <span className="mx-2">or</span>
                <div className="form-group text-start mb-3">
                  <label className="form-label" htmlFor="inputAge">Age (read only)</label>
                  <input
                    className="form-control"
                    type="text"
                    id="inputAge"
                    readOnly={true}
                    {...formik.getFieldProps('age')}
                  />
                  {formik.touched.age && formik.errors.age ? (
                    <div className="text-danger">{formik.errors.age}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  id="checkedCheckbox"
                  type="checkbox"
                  {...formik.getFieldProps('termsAccepted')}
                />
                <label className="form-check-label text-muted fw-normal" htmlFor="checkedCheckbox">
                  I agree with the terms & policy.
                </label>
                {formik.touched.termsAccepted && formik.errors.termsAccepted ? (
                  <div className="text-danger">{formik.errors.termsAccepted}</div>
                ) : null}
              </div>
              {/* Hidden input field for phone number */}
              <input
                type="hidden"
                {...formik.getFieldProps('mobile')}
              />
              <button className="btn btn-primary w-100" type="submit">
                Submit
              </button>
            </form>
          </div>
          <div className="login-meta-data text-center">
            <p className="mt-3 mb-0">
              Already have an account? <Link className="stretched-link" to="/Login"> Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
