import React, { useEffect, useState } from 'react';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from './HomeFooter';
import LoadingN from './Loader';
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import { Sheet } from 'react-modal-sheet';

const Changeprofile = () => {
    const location = useLocation();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [currentProfileId, setCurrentProfileId] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
 
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
        const searchParams = new URLSearchParams(location.search);
        const getid = searchParams.get('getid');
        const state=searchParams.get('state');
        const city=searchParams.get('city');
        const login=searchParams.get('login');
        const pincode=searchParams.get('pincode');
        const response = await fetch(`${API_BASE_URL}/supervisor/supervisorProfile?login=${login}&userid=${getid}&state=${state}&city=${city}&pincode=${pincode}`);
        const result = await response.json();

      if (result) {
        setProfiles(Array.isArray(result) ? result : [result]);
        const currentProfile = localStorage.getItem('regid');
        setCurrentProfileId(currentProfile);
      } else {
        notify('info', 'No data found');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      notify('error', 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
   
    getSwitchButtonClass(localStorage.getItem('regid'));
    fetchData();
  
  }, []);

  const handleEdit = async(id) => {
    const switchProfileUrl = `${API_BASE_URL}/supervisor/switchprofile?userid=${id}`;

    try {
      const response = await fetch(switchProfileUrl, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      localStorage.setItem('firstname', responseData.staff_name);
      localStorage.setItem('mobile', responseData.mobile);
      localStorage.setItem('photo', responseData.staff_photo);
      localStorage.setItem('intertype', responseData.name);
      localStorage.setItem('state', responseData.staff_state);
      localStorage.setItem('city', responseData.staff_city);
      localStorage.setItem('status', responseData.active);
      localStorage.setItem('regid', responseData.staff_id);
      setCurrentProfileId(responseData.reg_id);
      navigate('/profile');
    } catch (error) {
      console.error(`Error switching profile: ${error}`);
      notify('error', 'Error in switching profile');
    } finally {
      setShowDeleteConfirmation(false);
    }
 
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedProfileId(id);
    setShowDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    if (!showDeleteConfirmation || !selectedProfileId) return;

    const deleteUrl = `${API_BASE_URL}/cpanel/userDelete?userid=${selectedProfileId}&mobile=${localStorage.getItem('mobile')}`;

    try {
      const response = await fetch(deleteUrl, {
        method: 'GET'
      });
      const responseData = await response.json();
      if (responseData.status === 1) {
        notify('success', responseData.message);
        setProfiles(profiles.filter(profile => profile.reg_id !== selectedProfileId));
      } else {
        notify('error', responseData.message);
      }
    } catch (error) {
      notify('error', 'Error deleting profile');
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleChangeProfile = async (id) => {
    const switchProfileUrl = `${API_BASE_URL}supervisor/switchprofile?userid=${id}`;

    try {
      const response = await fetch(switchProfileUrl, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      localStorage.setItem('firstname', responseData.firstname);
      localStorage.setItem('lastname', responseData.lastname);
      localStorage.setItem('selectGender', responseData.selectGender);
      localStorage.setItem('selectBusiness', responseData.selectBusiness);
      localStorage.setItem('selectedDate', responseData.selectedDate);
      localStorage.setItem('age', responseData.age);
      localStorage.setItem('mobile', responseData.mobile);
      localStorage.setItem('userId', responseData.user_id);
      localStorage.setItem('regid', responseData.reg_id);
      localStorage.setItem('maritalstatus', responseData.maritalstatus);
      localStorage.setItem('bank_account', responseData.bank_account); 
      localStorage.setItem('monthly_income', responseData.monthly_income); 
      localStorage.setItem('own_land', responseData.own_land); 
      localStorage.setItem('ration_card_type', responseData.ration_card_type); 
      localStorage.setItem('religion', responseData.religion); 
      localStorage.setItem('caste', responseData.caste); 
      localStorage.setItem('nature_of_Job', responseData.nature_of_Job); 
      localStorage.setItem('occupation', responseData.occupation); 
      localStorage.setItem('state', responseData.state); 
      setCurrentProfileId(responseData.reg_id);
      
      notify('success', `Switched to profile: ${responseData.firstname} ${responseData.lastname}`);
    } catch (error) {
      console.error(`Error switching profile: ${error}`);
      notify('error', 'Error in switching profile');
    } finally {
      setShowDeleteConfirmation(false);
    }

  };

  const handleAddProfile = () => {
    navigate('/ChildProfile'); // Example: replace with your actual add profile path or form logic
  };

  const getSwitchButtonClass = (profileId) => {
   
    return currentProfileId === profileId ? 'badge fw-normal bg-primary' : 'badge fw-normal bg-success';
  };

  return (
    <>
      <Settingpop />
      <NotificationExample />
      <div className="header-area" id="headerArea">
        <div className="container">
          <div className="header-content position-relative d-flex align-items-center justify-content-between">
            <div className="back-button">
              <Link to="/Home">
                <i className="bi bi-arrow-left-short"></i>
              </Link>
            </div>
            <div className="page-heading">
              <h2 className="mb-0">Supervisor List</h2>
            </div>
            <div className="setting-wrapper d-flex align-items-center">
              <div className="setting-trigger-btn" id="settingTriggerBtn">
              <Link to="/Language">
                  <i className="bi bi-gear"></i>
                  </Link>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="pt-3 d-block mt-5 mb-2">
          {/* <button className="btn btn-primary w-100" onClick={handleAddProfile}>
            <i className="bi bi-plus-circle"></i>  Add a Profile
          </button> */}
        </div>
        {loading && <LoadingN />}
        {profiles.map((profile, index) => {
          const randomColor = getRandomColor();
          return (
            <div className="card timeline-card" key={index} style={{ borderLeft: `0.5rem solid ${randomColor}` }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="timeline-text mb-2">
                   <span className="text-secondary">{profile.staff_state}, {profile.staff_city}, {profile.staff_pincode}</span> 
                  
                    <h4>{profile.staff_name}</h4>
                    <h6 style={{color:'green'}}>{profile.name}</h6>
                  </div>
                  {/* <div className="timeline-icon mb-2">
                 
                  <Link to="/Home">
                  <i className="bi bi-person-circle h1 mb-0" />
                 </Link> 
                
                 
                   
                  </div> */}
                </div>
             
               
                 
               
            
                
                <div className="timeline-tags d-flex" >
                <span className="badge fw-normal text-danger " style={{ cursor: 'pointer',fontSize:'20px' }} >
                      <i className="bi bi-trash"></i>
                    </span>
                  <span className="badge fw-normal text-primary" style={{ cursor: 'pointer',fontSize:'20px' }}>
                    <i className="bi bi-pencil-square"></i>
                  </span>
                  <span className="badge fw-normal text-success" style={{ cursor: 'pointer',fontSize:'20px' }}>
                    <i className="bi bi-eye-fill"></i>
                  </span>
                  {/* <span className={getSwitchButtonClass(profile.reg_id)} onClick={() => handleChangeProfile(profile.reg_id)} style={{ cursor: 'pointer' }}>
                    <i className="bi bi-arrow-repeat"></i> {currentProfileId === profile.reg_id ? ' Selected profile' : ' Switch your profile'}
                  </span> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Sheet isOpen={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)} detent="content-height">
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="p-4">
              <h2>Are you sure you want to delete this account?</h2>
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-secondary me-2" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
                <button className="btn btn-danger" onClick={handleDelete}>Yes, I want</button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <HomeFooter />
    </>
  );
};

export default Changeprofile;
