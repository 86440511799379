

import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';

const Testimonialsider = () => {
  useEffect(() => {
   
 

      // Cleanup slider on component unmount
     
    const testimonial = tns({
      container: '.testimonial-slide3',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 5000,
      speed: 800,
      mouseDrag: true,
      controls: false,
      nav: true,
      navPosition: 'bottom'
  });
      // Cleanup slider on component unmount
      return () => {
      
        testimonial.destroy();
      };
    
    
    // Initialize the slider after the component is mounted
    
    

  }, []);


  return (
 
    <div className="container">
    <div className="card mb-3">
      <div className="card-body">
        <h3>Customer Review</h3>
        <div className="testimonial-slide-three-wrapper">
          <div className="testimonial-slide3 testimonial-style3">
            {/* Single Testimonial Slide */}
            {[
              {
                rating: 5,
                text: 'The code looks clean, and the designs are excellent. I recommend.',
                author: 'Mrrickez, Themeforest',
              },
              {
                rating: 5,
                text: 'All complete, <br> great craft.',
                author: 'Mazatlumm, Themeforest',
              },
              {
                rating: 5,
                text: 'Awesome template! <br> Excellent support!',
                author: 'Vguntars, Themeforest',
              },
              {
                rating: 5,
                text: 'Nice modern design, <br> I love the product.',
                author: 'electroMEZ, Themeforest',
              },
            ].map((testimonial, index) => (
              <div key={index} className="single-testimonial-slide">
                <div className="text-content">
                  <span className="d-inline-block badge bg-warning mb-2">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill me-1"></i>
                    ))}
                  </span>
                  <h6 className="mb-2" dangerouslySetInnerHTML={{ __html: testimonial.text }}></h6>
                  <span className="d-block">{testimonial.author}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default Testimonialsider;
