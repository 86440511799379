import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';

const HomeFooter = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="footer-nav-area" id="footerNav">
      <div className="container px-0">
        {/* Footer Content */}
        <div className="footer-nav position-relative">
          <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
            <li className={pathname === '/home' ? 'active' : ''}>
              <Link to="/home">
                <i className="bi bi-house"></i>
                <span>Home</span>
              </Link>
            </li>
            <li className={pathname === '/Yojanalist' ? 'active' : ''}>
              <Link to="/Yojanalist">
                <i className="bi bi-card-checklist"></i>
                <span>Schemes</span>
              </Link>
            </li>
            <li className={pathname === '/MyBooking' ? 'active' : ''}>
              <Link to="/MyBooking">
                <i className="bi bi-bookmark"></i>
                <span>Beneficiary  Scheme</span>
              </Link>
            </li>
            <li className={pathname === '/Profile' ? 'active' : ''}>
              <Link to="/Changeprofile">
                <i className="bi bi-person"></i>
                <span>Profile</span>
              </Link>
            </li>
            <li className={pathname === '/Language' ? 'active' : ''}>
              <Link to="/Language">
                <i className="bi bi-gear"></i>
                <span>Settings</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
