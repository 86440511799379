import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { auth } from './firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js';

const Qrcode = ({ onScan }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isCooldown, setIsCooldown] = useState(false);
  const navigate = useNavigate(); // useNavigate hook

  useEffect(() => {
    if (!recaptchaVerifier) {
      const verifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        }
      }, auth);
      verifier.render().then(() => {
        setRecaptchaVerifier(verifier);
      }).catch((error) => {
        console.error("Error initializing reCAPTCHA verifier:", error);
      });
    }

    const setupQrCodeScanner = () => {
      const html5QrcodeScanner = new Html5QrcodeScanner(
        "my-qr-reader",
        { fps: 10, qrbox: 250 }
      );

      html5QrcodeScanner.render(onScanSuccess);

      return () => {
        html5QrcodeScanner.clear();
      };
    };

    const cleanupQrCodeScanner = setupQrCodeScanner();

    return () => {
      cleanupQrCodeScanner();
    };
  }, [recaptchaVerifier]);

  const onScanSuccess = (decodeText, decodeResult) => {
    const url = `https://suryodayfoundation.org/new/welcome/otpverify?get=${encodeURIComponent(decodeText)}`;
    console.log('Fetching URL:', url);
  
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetch successful, data received:', data);
        
        if (data && data.key) {
          console.log('data.key found:', data.key);
          setPhoneNumber('+91'+data.key);
          sendOTP();
        } else {
          console.warn('data.key is not available or undefined:', data);
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };
  
  const sendOTP = () => {
    if (isCooldown) {
      alert('Please wait before requesting another OTP');
      return;
    }

    if (!recaptchaVerifier) {
      alert('reCAPTCHA verifier not initialized');
      return;
    }

    setIsCooldown(true);
    setTimeout(() => setIsCooldown(false), 60000); // 60 seconds cooldown

    signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
      .then((result) => {
        setConfirmationResult(result);
        alert('OTP sent');
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const verifyOtp = () => {
    if (!confirmationResult) {
      alert('No OTP sent');
      return;
    }
    confirmationResult.confirm(code)
      .then((result) => {
        const user = result.user;
        alert("Verification successful");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on the next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Replace this with your form submission logic
    setCode(otp.join(""));
    verifyOtp();
  };

  return (
    <>
      <div className="login-back-button">
        <Link to="/login">
          <i className="bi bi-arrow-left-short"></i>
        </Link>
      </div>
      {!confirmationResult ? (
        <div className="login-wrapper d-flex align-items-center justify-content-center">
          <div className="custom-container">
            <div className="text-center">
              <h3>QR Code Verification</h3>
              <p>We will send you an OTP code on this phone number.</p>
              <div id="recaptcha-container"></div>
              <div id="my-qr-reader"></div>
              {/* <button onClick={sendOTP}>Send OTP</button> */}
              {/* <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
              />
              <button onClick={verifyOtp}>Verify</button> */}
            </div>
            <div className="login-meta-data px-4 text-center">
              <p className="mt-3 mb-0">I hereby agree to the <Link className="stretched-link" to="/privacy-policy">Terms of Service</Link> and <Link className="stretched-link" to="/privacy-policy">Privacy Policy</Link>.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-wrapper d-flex align-items-center justify-content-center">
          <div className="custom-container">
            <div className="text-center">
              <img className="mx-auto mb-4 d-block" src="img/bg-img/38.png" alt="" />
              <h3>Verify Phone Number</h3>
              <p className="mb-4">Enter the OTP code sent to <strong>012 3456 7890</strong></p>
            </div>

            <div className="otp-verify-form mt-4">
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3 otp-input-group">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="form-control"
                        type="text"
                        value={data}
                        key={index}
                        maxLength="1"
                        onChange={e => handleChange(e.target, index)}
                        onFocus={e => e.target.select()}
                        placeholder="-"
                      />
                    );
                  })}
                </div>
                <button className="btn btn-primary w-100" type="submit">Verify &amp; Proceed</button>
              </form>
            </div>

            <div className="login-meta-data text-center">
              <p className="mt-3 mb-0">Don't received the OTP? <span className="otp-sec" id="resendOTP"></span></p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Qrcode;
