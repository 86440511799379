import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import HomeFooter from "./HomeFooter";
import { useLocation, useNavigate} from "react-router-dom";
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import LoadingN from './Loader';
const Profile = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstname: '',
    intertype: '',
    mobile: '',
    state: '',
    city: '',
    status: '',
   
  });

  // const [selectOptions, setSelectOptions] = useState({
  //   applicationmode: [],
  //   belowpovertyline: [],
  //   benefittype: [],
  //   caste: [],
  //   dbtscheme: [],
  //   differentlyabled: [],
  //   disabilitypercentage: [],
  //   economicdistress: [],
  //   employmentstatus: [],
  //   gender: [],
  //   governmentemployee: [],
  //   maritalstatus: [],
  //   ministryname: [],
  //   minority: [],
  //   occupation: [],
  //   residence: [],
  //   schemecategory: [],
  //   schemetype: [],
  //   state: [],
  //   student: []
  // });

  useEffect(() => {
    const titles = [
      'firstname',
      'intertype',
      'mobile',
      'state',
      'city',
      'status',
      
   
    ];

    titles.forEach((title) => {
      const storedValue = localStorage.getItem(title);
      if (storedValue) {
        setProfileData((prevData) => ({ ...prevData, [title]: storedValue }));
      }
    });

    // Fetch select options from the API
    // titles.forEach(async (title) => {
    //   if (!['firstname', 'lastname', 'mobile', 'selectGender', 'selectBusiness', 'selectedDate', 'age'].includes(title)) {
    //     try {
    //       const response = await fetch(`${API_BASE_URL}cpanel/getAllMaster/${title}`);
    //       const data = await response.json();
    //       setSelectOptions((prevOptions) => ({ ...prevOptions, [title]: data }));
    //     } catch (error) {
    //       console.error(`Error fetching ${title} data:`, error);
    //     }
    //   }
    // });
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [id]: value }));
    localStorage.setItem(id, value); // Save to localStorage whenever there's a change
  };

  const handleSubmit = async (e) => {
                              e.preventDefault();
    const reg_id = localStorage.getItem('regid');
    const dataToSubmit = { ...profileData, reg_id };
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}cpanel/saveProfileData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSubmit)
      });
      const result = await response.json();
      if (result.Status) {
        navigate('/Completeprofile');
       } else {
        notify('error', result.message);
        navigate('/Completeprofile');
      }
    } catch (error) {
      console.error('Error submitting profile data:', error);
    }
  };

  // const labelMapping = {
  //   applicationmode: 'Application Mode',
  //   belowpovertyline: 'Below Poverty Line',
  //   benefittype: 'Benefit Type',
  //   caste: 'Caste',
  //   dbtscheme: 'DBT Scheme',
  //   differentlyabled: 'Differently Abled',
  //   disabilitypercentage: 'Disability Percentage',
  //   economicdistress: 'Economic Distress',
  //   employmentstatus: 'Employment Status',
  //   gender: 'Gender',
  //   governmentemployee: 'Government Employee',
  //   maritalstatus: 'Marital Status',
  //   ministryname: 'Ministry Name',
  //   minority: 'Minority',
  //   occupation: 'Occupation',
  //   residence: 'Residence',
  //   schemecategory: 'Scheme Category',
  //   schemetype: 'Scheme Type',
  //   state: 'State',
  //   student: 'Student'
  // };

  return (
    <>
     <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Changeprofile">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Profile</h2>
              </div>
              <div className="setting-wrapper">
              <Link to="/Language">
                  <i className="bi bi-gear"></i>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper py-3">
        {/* Element Heading */}
        <div className="container">
          <div className="element-heading">
            <h6>Profile</h6>
          </div>
        </div>
        {loading && <LoadingN />}
        <div className="container">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="firstname">First Name</label>
                  <input
                    className="form-control"
                    id="firstname"
                    type="text"
                    value={profileData.firstname}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                {/* <div className="form-group">
                  <label className="form-label" htmlFor="lastname">Last Name</label>
                  <input
                    className="form-control"
                    id="lastname"
                    type="text"
                    value={profileData.lastname}
                    onChange={handleChange}
                    readOnly
                  />
                </div> */}

                <div className="form-group">
                  <label className="form-label" htmlFor="mobile">Mobile</label>
                  <input
                    className="form-control"
                    id="mobile"
                    type="text"
                    value={profileData.mobile}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectGender">Intern type</label>
                  <input
                    className="form-control"
                    id="selectGender"
                    type="text"
                    value={profileData.intertype}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectBusiness">State</label>
                  <input
                    className="form-control"
                    id="selectBusiness"
                    type="text"
                    value={profileData.state}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectedDate">City</label>
                  <input
                    className="form-control"
                    id="selectedDate"
                    type="text"
                    value={profileData.city}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="age">Status</label>
                  <input
                    className="form-control"
                    id="age"
                    type="text"
                    value={profileData.status}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
{/* 
                {Object.keys(profileData).filter(key => !['firstname', 'lastname', 'mobile', 'selectGender', 'selectBusiness', 'selectedDate', 'age'].includes(key)).map((title) => (
                  <div className="form-group" key={title}>
                    <label className="form-label" htmlFor={title}>{labelMapping[title] || title}</label>
                    <select
                      className="form-control"
                      id={title}
                      value={profileData[title]}
                      onChange={handleChange}
                    >
                      <option value="">Select {labelMapping[title] || title}</option>
                      {Array.isArray(selectOptions[title]) && selectOptions[title].map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                ))} */}

                <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
               Update Profile
                  <i className="bi bi-arrow-right fz-16 ms-1"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Profile;
