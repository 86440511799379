import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Global() {
    function removeExtraFade() {
        let fade = document.getElementsByClassName('offcanvas-backdrop fade show');
    for (let i = 0; i < fade.length; i++) {
      while (fade.length > 0) {
        fade[i].remove();
      }
    }
    }

    const location = useLocation();

    useEffect(() => {
        removeExtraFade();
    }, [location]);

    return null; // This component does not render anything visible
}

export default Global;

