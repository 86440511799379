import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { API_BASE_URL } from './Config';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import NotificationExample, { notify } from './NotificationExample';
import { auth } from './firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js';

const Otpconfirm = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [isCooldown, setIsCooldown] = useState(true);
  const [countdown, setCountdown] = useState(120); // 2 minutes countdown
  const [page, setPage] = useState(false);
  const [userData, setUserdata] = useState({});
  const [message,setMessage]= useState('');
  const [wait,setWait]= useState('Verify Proceed');
  useEffect(() => {
    if (!recaptchaVerifier) {
      const verifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        }
      }, auth);
      verifier.render().then(() => {
        setRecaptchaVerifier(verifier);
      }).catch((error) => {
        console.error("Error initializing reCAPTCHA verifier:", error);
      });
    }
    getPhoneNumberVerification();
  }, [location.search,recaptchaVerifier]);

  const sendOTP = () => {
 
    signInWithPhoneNumber(auth, `+91${phoneNumber}`, recaptchaVerifier)
      .then((result) => {
        setConfirmationResult(result);
        setMessage(1);
        
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  useEffect(() => {
    if (isCooldown) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsCooldown(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isCooldown]);

  const getPhoneNumberVerification = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const phone = searchParams.get('phone');
      setPhoneNumber(phone);
      
      const response = await axios.get(`${API_BASE_URL}supervisor/insertValidPhone/${phone}`);
      
      if (response.data.success) {
        setPage(response.data.page);
        setUserdata(response.data.userinfo);
       sendOTP();
      } else {
        setPage(false);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const verifyOtp = () => {
    // Check if confirmationResult is available
    if (!confirmationResult) {
        setMessage('No OTP sent');
        return;
    }
    
    setWait('wait...');
    const code = otp.join('');
    
    // Check if code is empty or not a 6-digit number
    if (code === "" || code.length !== 6 || isNaN(code)) {
        setWait('Verify Proceed');
        setMessage('Please enter a valid 6-digit OTP.');
        return;
    }
    
    confirmationResult.confirm(code)
        .then((result) => {
            const user = result.user;
            if (page === "Home") {
                login(userData);
                notify('success', 'Welcome! You have successfully logged in.');
                navigate(`/${page}`);
            } else {
                notify('error', 'You are not registered as a supervisor. Please contact your branch to complete the registration process.');
                navigate(`/${page}?phone=${phoneNumber}`);
                setWait('Verify Proceed');
            }
        })
        .catch((error) => {
            setWait('Verify Proceed');
            setMessage(error.message);
        });
};


  const handleResendOtp = async () => {
    // try {
    //   const response = await axios.get(`${API_BASE_URL}cpanel/resendOtp/${phoneNumber}`);
    //   if (response.data.success) {
    //     setOtp(new Array(6).fill("")); // Reset OTP input
    //     setIsCooldown(true);
    //     setCountdown(120); // Reset the countdown
    getPhoneNumberVerification();
    //   } else {
    //     console.error('Error resending OTP');
    //   }
    // } catch (error) {
    //   console.error('Error resending OTP: ', error);
    // }
  };

  return (
    <>
      <NotificationExample />
      <div className="login-back-button">
        <Link to="/Otp">
          <i className="bi bi-arrow-left-short"></i>
        </Link>
      </div>

      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <div className="text-center">
            <img className="mx-auto mb-4 d-block" src="img/bg-img/38.png" alt="" />
            <h3>Verify Phone Number</h3>
            {message==1?
             <p className="mb-4" style={{color:'green'}}>Code sent successfully. Wait until the OTP is received.</p>
            :
            <>
            <p className="mb-4">Enter the OTP code sent to <strong>{phoneNumber}</strong></p>
            {/* <p className="mb-4" style={{color:'red'}}>{message}</p> */}
            </>
            }
           
          </div>
          <div id="recaptcha-container"></div>
          <div className="otp-verify-form mt-4">
            <div className="input-group mb-3 otp-input-group">
              {otp.map((data, index) => {
                return (
                  <input
                    className="form-control"
                    type="text"
                    value={data}
                    key={index}
                    maxLength="1"
                    onChange={e => handleChange(e.target, index)}
                    onFocus={e => e.target.select()}
                    placeholder="-"
                    inputMode="numeric"
                  />
                );
              })}
            </div>
            <button className="btn btn-primary w-100" onClick={verifyOtp} >{wait}</button>
          </div>

          <div className="login-meta-data text-center">
            {isCooldown ? (
              <p className="mt-3 mb-0">Resend OTP in {countdown} seconds</p>
            ) : (
              <p className="mt-3 mb-0">Didn't receive the OTP? <button className="btn btn-link p-0" onClick={handleResendOtp}>Resend OTP</button></p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Otpconfirm;
